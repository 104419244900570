import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building9B = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 9B'});

	console.log('b=', b);
	return (
		<g id='BUILDING-9B'>
			<g
				onClick={() => click('F1')}
				className={unitClass(b, '87')}
				id='UNIT-87-F1'
			>
				<polygon
					className={S.Hot}
					points='4732.11,2049.285 4732.11,2145.083 4751.567,2145.083 4751.567,2222.222 4772.881,2222.222
				4772.659,2232.927 4823.938,2233.056 4823.938,2049.285 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4761.918 2097.124)'
						className={S.Number}
					>
						87
					</text>
					<text
						transform='matrix(1 0 0 1 4764.8413 2126.5034)'
						className={S.Plan}
					>
						F1
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G')}
				className={unitClass(b, '88')}
				id='UNIT-88-G'
			>
				<polygon
					className={S.Hot}
					points='4732.11,2145.083 4732.11,2049.285 4680.199,2049.285 4680.202,2233.491 4731.251,2233.491
				4731.336,2222.222 4751.567,2222.222 4751.567,2145.083 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4699.5039 2173.3298)'
						className={S.Number}
					>
						88
					</text>
					<text
						transform='matrix(1 0 0 1 4705.6904 2202.709)'
						className={S.Plan}
					>
						G
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G')}
				className={unitClass(b, '89')}
				id='UNIT-89-G'
			>
				<polygon
					className={S.Hot}
					points='4628.284,2049.285 4628.284,2145.083 4608.827,2145.083 4608.827,2222.222 4629.148,2222.222
				4629.148,2233.491 4680.202,2233.491 4680.199,2049.285 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4627.7969 2173.3301)'
						className={S.Number}
					>
						89
					</text>
					<text
						transform='matrix(1 0 0 1 4634.3213 2202.709)'
						className={S.Plan}
					>
						G
					</text>
				</g>
			</g>
			<g
				onClick={() => click('F1')}
				className={unitClass(b, '90')}
				id='UNIT-90-F1'
			>
				<polygon
					className={S.Hot}
					points='4628.284,2145.083 4628.284,2049.285 4536.46,2049.285 4537.459,2233.056 4586.833,2233.056
				4586.847,2222.222 4608.827,2222.222 4608.827,2145.083 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4564.4346 2100.124)'
						className={S.Number}
					>
						90
					</text>
					<text
						transform='matrix(1 0 0 1 4569.1909 2129.5032)'
						className={S.Plan}
					>
						F1
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building9B;
