import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building12 = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 12'});
	return (
		<g id='BUILDING-12'>
			<g
				onClick={() => click('A4')}
				className={unitClass(b, '122')}
				id='UNIT-122-A4'
			>
				<polygon
					className={S.Hot}
					points='3614.583,2363.405 3604.558,2363.405 3604.558,2404.418 3614.583,2404.418 3712.102,2404.418
				3747.646,2404.418 3747.646,2312.367 3614.583,2312.367 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3660.5886 2351.3574)'
						className={S.Number}
					>
						122
					</text>
					<text
						transform='matrix(1 0 0 1 3662.6802 2380.7363)'
						className={S.Plan}
					>
						A4
					</text>
				</g>
			</g>
			<g
				onClick={() => click('Aa')}
				className={unitClass(b, '123')}
				id='UNIT-123-Aa'
			>
				<polygon
					className={S.Hot}
					points='3614.583,2272.266 3604.558,2272.266 3604.558,2312.367 3614.583,2312.367 3747.646,2312.367
				3750.836,2312.367 3750.836,2221.456 3614.583,2221.456 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3660.5254 2261.2598)'
						className={S.Number}
					>
						123
					</text>
					<text
						transform='matrix(1 0 0 1 3663.8777 2290.6387)'
						className={S.Plan}
					>
						Aa
					</text>
				</g>
			</g>
			<g
				onClick={() => click('Aa')}
				className={unitClass(b, '124')}
				id='UNIT-124-Aa'
			>
				<polygon
					className={S.Hot}
					points='3747.646,2130.544 3614.583,2130.544 3614.583,2180.671 3604.558,2180.671 3604.558,2221.456
				3614.583,2221.456 3750.836,2221.456 3750.836,2130.544 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3659.6929 2171.1631)'
						className={S.Number}
					>
						124
					</text>
					<text
						transform='matrix(1 0 0 1 3663.8772 2200.542)'
						className={S.Plan}
					>
						Aa
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A2')}
				className={unitClass(b, '125')}
				id='UNIT-125-A2'
			>
				<polygon
					className={S.Hot}
					points='3747.646,2039.405 3614.583,2039.405 3614.583,2090.899 3604.558,2090.899 3604.558,2130.544
				3614.583,2130.544 3747.646,2130.544 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3660.4592 2081.0669)'
						className={S.Number}
					>
						125
					</text>
					<text
						transform='matrix(1 0 0 1 3663.6172 2110.4458)'
						className={S.Plan}
					>
						A2
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building12;
