import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building9A = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 9A'});
	return (
		<g id='BUILDING-9A'>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '86')}
				id='UNIT-86-F3'
			>
				<polygon
					className={S.Hot}
					points='5015.834,2390.712 4937.782,2390.712 4937.782,2412.893 4926.704,2412.893 4926.704,2463.114
				5111.355,2463.114 5111.571,2371.255 5015.834,2371.255 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5042.4419 2412.9895)'
						className={S.Number}
					>
						86
					</text>
					<text
						transform='matrix(1 0 0 1 5042.6299 2442.3687)'
						className={S.Plan}
					>
						F3
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G')}
				className={unitClass(b, '85')}
				id='UNIT-85-G'
			>
				<polygon
					className={S.Hot}
					points='4926.868,2370.099 4937.782,2370.099 4937.782,2390.712 5015.834,2390.712 5015.834,2371.255
				5111.571,2371.255 5111.689,2320.824 4926.868,2320.827 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4963.812 2351.8052)'
						className={S.Number}
					>
						85
					</text>
					<text
						transform='matrix(1 0 0 1 4969.9072 2381.1843)'
						className={S.Plan}
					>
						G
					</text>
				</g>
			</g>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '84')}
				id='UNIT-84-F'
			>
				<polygon
					className={S.Hot}
					points='5015.834,2250.478 4937.646,2250.478 4937.646,2270.851 4926.868,2270.851 4926.868,2320.827
				5111.689,2320.824 5111.9,2231.021 5015.834,2231.021 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5042.104 2271.843)'
						className={S.Number}
					>
						84
					</text>
					<text
						transform='matrix(1 0 0 1 5050.6299 2301.2222)'
						className={S.Plan}
					>
						F
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G')}
				className={unitClass(b, '83')}
				id='UNIT-83-G'
			>
				<polygon
					className={S.Hot}
					points='4927.168,2179.113 4927.168,2228.946 4937.646,2228.946 4937.646,2250.478 5015.834,2250.478
				5015.834,2231.021 5111.9,2231.021 5112.022,2179.11 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4963.8774 2210.6587)'
						className={S.Number}
					>
						83
					</text>
					<text
						transform='matrix(1 0 0 1 4969.9072 2240.0378)'
						className={S.Plan}
					>
						G
					</text>
				</g>
			</g>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '82')}
				id='UNIT-82-F'
			>
				<polygon
					className={S.Hot}
					points='5015.834,2086.92 5015.834,2106.377 4937.646,2106.377 4937.646,2129.192 4927.168,2129.192
				4927.168,2179.113 5112.022,2179.11 5112.239,2086.92 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5043.0005 2130.6965)'
						className={S.Number}
					>
						82
					</text>
					<text
						transform='matrix(1 0 0 1 5050.6299 2160.0757)'
						className={S.Plan}
					>
						F
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G1')}
				className={unitClass(b, '81')}
				id='UNIT-81-G1'
			>
				<polygon
					className={S.Hot}
					points='5015.834,2086.92 5112.239,2086.92 5112.354,2037.843 4926.868,2037.843 4926.868,2086.401
				4937.646,2086.401 4937.646,2106.377 5015.834,2106.377 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4966.7632 2069.5122)'
						className={S.Number}
					>
						81
					</text>
					<text
						transform='matrix(1 0 0 1 4965.2539 2098.8914)'
						className={S.Plan}
					>
						G1
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building9A;
