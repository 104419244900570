import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building8A = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 8A'});
	return (
		<g id='BUILDING-8A'>
			<g
				onClick={() => click('A4')}
				className={unitClass(b, '56')}
				id='UNIT-56-A4'
			>
				<polygon
					className={S.Hot}
					points='5517.59,2395.26 5517.59,2447.35 5503.37,2447.35 5503.37,2450.63 5509.3,2450.63 5509.3,2487.16
				5650.65,2487.16 5650.65,2395.26 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5567.0811 2437.2913)'
						className={S.Number}
					>
						56
					</text>
					<text
						transform='matrix(1 0 0 1 5564.7134 2466.6704)'
						className={S.Plan}
					>
						A4
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A')}
				className={unitClass(b, '57')}
				id='UNIT-57-A'
			>
				<polygon
					className={S.Hot}
					points='5650.65,2303.73 5515.59,2303.73 5515.59,2355.45 5500.37,2355.45 5500.37,2358.73 5506.3,2358.73
				5506.3,2395.26 5517.59,2395.26 5650.65,2395.26 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5567.6924 2346.7075)'
						className={S.Number}
					>
						57
					</text>
					<text
						transform='matrix(1 0 0 1 5573.437 2376.0864)'
						className={S.Plan}
					>
						A
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A')}
				className={unitClass(b, '58')}
				id='UNIT-58-A'
			>
				<polygon
					className={S.Hot}
					points='5650.65,2213.47 5517.59,2213.47 5517.59,2263.93 5502.37,2263.93 5502.37,2267.21 5508.3,2267.21
				5508.3,2303.73 5515.59,2303.73 5650.65,2303.73 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5567.4189 2256.1235)'
						className={S.Number}
					>
						58
					</text>
					<text
						transform='matrix(1 0 0 1 5573.437 2285.5027)'
						className={S.Plan}
					>
						A
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A')}
				className={unitClass(b, '59')}
				id='UNIT-59-A'
			>
				<polygon
					className={S.Hot}
					points='5650.65,2121.21 5517.59,2121.21 5517.59,2173.66 5504.37,2173.66 5504.37,2176.94 5510.3,2176.94
				5510.3,2213.47 5517.59,2213.47 5650.65,2213.47 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5567.0811 2165.54)'
						className={S.Number}
					>
						59
					</text>
					<text
						transform='matrix(1 0 0 1 5573.437 2194.9194)'
						className={S.Plan}
					>
						A
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A2')}
				className={unitClass(b, '60')}
				id='UNIT-60-A2'
			>
				<polygon
					className={S.Hot}
					points='5650.65,2030.48 5517.59,2030.48 5517.59,2081.4 5504.37,2081.4 5504.37,2084.68 5510.3,2084.68
				5510.3,2121.21 5517.59,2121.21 5650.65,2121.21 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5565.7686 2074.9568)'
						className={S.Number}
					>
						60
					</text>
					<text
						transform='matrix(1 0 0 1 5565.6499 2104.3359)'
						className={S.Plan}
					>
						A2
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building8A;
