import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building18 = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 18'});
	return (
		<g id='BUILDING-18'>
			<g
				onClick={() => click('B2')}
				className={unitClass(b, '97')}
				id='UNIT-97-B2'
			>
				<polygon
					className={S.Hot}
					points='4368.166,1777.799 4461.039,1777.799 4529.424,1777.799 4534.968,1777.799 4534.968,1717.981
				4529.424,1717.981 4529.424,1686.383 4368.166,1686.383 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4432.2217 1726.0232)'
						className={S.Number}
					>
						97
					</text>
					<text
						transform='matrix(1 0 0 1 4431.4917 1755.4021)'
						className={S.Plan}
					>
						B2
					</text>
				</g>
			</g>
			<g
				onClick={() => click('B')}
				className={unitClass(b, '98')}
				id='UNIT-98-B'
			>
				<polygon
					className={S.Hot}
					points='4368.166,1686.383 4529.424,1686.383 4534.968,1686.383 4534.968,1626.315 4529.424,1626.315
				4529.424,1594.985 4368.166,1594.985 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4431.9482 1635.584)'
						className={S.Number}
					>
						98
					</text>
					<text
						transform='matrix(1 0 0 1 4439.2788 1664.9631)'
						className={S.Plan}
					>
						B
					</text>
				</g>
			</g>
			<g
				onClick={() => click('B')}
				className={unitClass(b, '99')}
				id='UNIT-99-B'
			>
				<polygon
					className={S.Hot}
					points='4368.166,1594.985 4529.424,1594.985 4534.968,1594.985 4534.968,1535.917 4529.424,1535.917
				4529.424,1505.587 4368.166,1505.587 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4431.6104 1545.145)'
						className={S.Number}
					>
						99
					</text>
					<text
						transform='matrix(1 0 0 1 4439.2793 1574.5239)'
						className={S.Plan}
					>
						B
					</text>
				</g>
			</g>
			<g
				onClick={() => click('B')}
				className={unitClass(b, '100')}
				id='UNIT-100-B'
			>
				<polygon
					className={S.Hot}
					points='4368.166,1413.947 4368.166,1505.587 4529.424,1505.587 4534.968,1505.587 4534.968,1443.519
				4529.424,1443.519 4529.424,1413.947 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4424.6938 1454.7061)'
						className={S.Number}
					>
						100
					</text>
					<text
						transform='matrix(1 0 0 1 4439.2783 1484.085)'
						className={S.Plan}
					>
						B
					</text>
				</g>
			</g>
			<g
				onClick={() => click('B1')}
				className={unitClass(b, '101')}
				id='UNIT-101-B1'
			>
				<polygon
					className={S.Hot}
					points='4534.968,1413.947 4534.968,1353.88 4529.424,1353.88 4529.424,1321.498 4368.166,1321.498
				4368.166,1413.947 4529.424,1413.947 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4428.958 1364.2668)'
						className={S.Number}
					>
						101
					</text>
					<text
						transform='matrix(1 0 0 1 4434.6245 1393.646)'
						className={S.Plan}
					>
						B1
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building18;
