import {useEffect} from '@wordpress/element';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {useAppDispatch} from '../../store';
import R from '../../constants/routes';
import Application from '../__Application';
import PageSitemap from '../Page_Sitemap';
import {fetchStatusBuildings, fetchUnits} from '../../store/townhomesSlice';
// import SectionNavigation from '../SectionNavigation';
// import Page_Townhome_Plans from '../Page_Townhome_Plans';

const RoutesContainer = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	/** *****************
	 * Load first page
	 ****************** */
	useEffect(() => {
		navigate(R.SITEMAP.pathname);
		dispatch(fetchUnits());
		dispatch(fetchStatusBuildings());
	}, []);

	return (
		<Application>
			{/*<SectionNavigation />*/}
			<Routes location={location} key={location.key}>
				<Route path={R.SITEMAP.pathname} element={<PageSitemap />} />
				{/*<Route*/}
				{/*	path={R['TOWNHOME-PLAN-TYPE'].pathname}*/}
				{/*	element={<Page_Townhome_Plans />}*/}
				{/*/>*/}
			</Routes>
		</Application>
	);
};

export default RoutesContainer;
