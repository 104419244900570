export type T__PageKey = 'SITEMAP' | 'TOWNHOME-PLAN-TYPE';

export interface I__Page {
	title: string;
	pathname: string;
}

const routes: {[key in T__PageKey]: I__Page} = {
	SITEMAP: {
		title: 'Sitemap',
		pathname: '/sitemap',
	},
	'TOWNHOME-PLAN-TYPE': {
		title: 'Townhome plan type',
		pathname: '/townhome-plan-type',
	},
};

export default routes;
