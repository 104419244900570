import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import S from './PageSitemap.module.sass';
import Sitemap from './Sitemap';
import {useEffect} from '@wordpress/element';
import {useDispatch} from 'react-redux';
import {updatePage} from '../../store/townhomesSlice';
import R from '../../constants/routes';

// const ScreenSizeDetector = require('screen-size-detector');

function PageSitemap() {
	const dispatch = useDispatch();
	// const screen = new ScreenSizeDetector();

	// console.log(`The current screen width is ${screen.width}`);

	useEffect(() => {
		dispatch(updatePage({page: R.SITEMAP}));
	}, []);

	// let defaultSetting = {
	// 	initialPositionX: -1158.83,
	// 	initialPositionY: -229.93,
	// 	initialScale: 4.349,
	// };
	//
	// if (screen.width > 576) {
	// 	defaultSetting = {
	// 		initialPositionX: -1148.7,
	// 		initialPositionY: -233.96,
	// 		initialScale: 4.349,
	// 	};
	// }
	//
	// if (screen.width > 768) {
	// 	defaultSetting = {
	// 		initialPositionX: -1374.7,
	// 		initialPositionY: -312.96,
	// 		initialScale: 4.349,
	// 	};
	// }
	//
	// if (screen.width > 992) {
	// 	defaultSetting = {
	// 		initialPositionX: -1547.42,
	// 		initialPositionY: -355.2,
	// 		initialScale: 3.758,
	// 	};
	// }
	//
	// if (screen.width > 1200) {
	// 	defaultSetting = {
	// 		initialPositionX: -1310.72,
	// 		initialPositionY: -303.22,
	// 		initialScale: 2.546,
	// 	};
	// }
	//
	// if (screen.width > 1400) {
	// 	defaultSetting = {
	// 		initialPositionX: -812.39,
	// 		initialPositionY: -162.6,
	// 		initialScale: 1.883,
	// 	};
	// }

	return (
		<TransformWrapper
		// wheel={{disabled: true, step: 0.05}}
		// panning={{disabled: false}}
		// doubleClick={{animationTime: 1000}}
		// zoomAnimation={{animationTime: 1000}}
		// initialScale={defaultSetting.initialScale}
		// initialPositionX={defaultSetting.initialPositionX}
		// initialPositionY={defaultSetting.initialPositionY}
		>
			{
				// @ts-ignore
				({zoomIn, zoomOut, resetTransform, ...rest}) => {
					return (
						<div className={S.PageSitemap}>
							<div className={S.SitemapContainer}>
								<div className={S.RatioContainer}>
									<main>
										<TransformComponent>
											<Sitemap />
										</TransformComponent>
									</main>
								</div>

								<div className={S.Bottom}>
									<ButtonGroup variant='outlined' disableElevation>
										<Button onClick={() => zoomIn()}>Zoom In</Button>
										<Button onClick={() => zoomOut()}>Zoom Out</Button>
										<Button onClick={() => resetTransform()}>Reset</Button>
									</ButtonGroup>
								</div>
							</div>
						</div>
					);
				}
			}
		</TransformWrapper>
	);
}

// @ts-ignore
export default PageSitemap;
