import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building4 = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 4'});
	return (
		<g id='BUILDING-4'>
			<rect
				id='BUILDING-4-PATCH'
				x='5395.766'
				y='676.557'
				className={S.Patch}
				width='29.089'
				height='51.038'
			/>
			<g
				onClick={() => click('A1')}
				className={unitClass(b, '25')}
				id='UNIT-25-A1'
			>
				<polygon
					className={S.Hot}
					points='5070.779,632.753 5070.779,623.924 5029.994,623.924 5029.994,766.785 5120.449,766.785
				5120.449,632.753 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5059.1548 694.4952)'
						className={S.Number}
					>
						25
					</text>
					<text
						transform='matrix(1 0 0 1 5060.2974 723.8743)'
						className={S.Plan}
					>
						A1
					</text>
				</g>
			</g>
			<g
				onClick={() => click('Aa')}
				className={unitClass(b, '26')}
				id='UNIT-26-Aa'
			>
				<polygon
					className={S.Hot}
					points='5161.234,632.753 5161.234,623.924 5120.449,623.924 5120.449,632.753 5120.449,766.785
				5120.449,769.747 5212.272,769.747 5212.272,632.753 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5150.105 694.4952)'
						className={S.Number}
					>
						26
					</text>
					<text
						transform='matrix(1 0 0 1 5148.8042 723.8743)'
						className={S.Plan}
					>
						Aa
					</text>
				</g>
			</g>
			<g
				onClick={() => click('Aa')}
				className={unitClass(b, '27')}
				id='UNIT-27-Aa'
			>
				<polygon
					className={S.Hot}
					points='5252.829,632.753 5252.829,623.924 5212.272,623.924 5212.272,632.753 5212.272,769.747
				5302.842,769.747 5302.842,766.785 5302.842,632.753 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5242.0962 694.4953)'
						className={S.Number}
					>
						27
					</text>
					<text
						transform='matrix(1 0 0 1 5240.1841 723.8743)'
						className={S.Plan}
					>
						Aa
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A2')}
				className={unitClass(b, '28')}
				id='UNIT-28-A2'
			>
				<polygon
					className={S.Hot}
					points='5395.766,632.753 5343.513,632.753 5343.513,623.924 5302.842,623.924 5302.842,632.753
				5302.842,766.785 5395.766,766.785 5395.766,727.595 5395.766,676.557 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5333.1455 694.4952)'
						className={S.Number}
					>
						28
					</text>
					<text
						transform='matrix(1 0 0 1 5331.2466 723.8742)'
						className={S.Plan}
					>
						A2
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building4;
