import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building20A = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 20A'});
	return (
		<g id='BUILDING-20A'>
			<rect
				id='BUILDING-20A-PATCH'
				x='5524.42'
				y='1292.89'
				className={S.Patch}
				width='49.67'
				height='27.75'
			/>
			<g
				onClick={() => click('A4')}
				className={unitClass(b, '61')}
				id='UNIT-61-A4'
			>
				<polygon
					className={S.Hot}
					points='5478.85,1737.51 5464.64,1737.51 5464.64,1740.79 5470.56,1740.79 5470.56,1777.32 5611.92,1777.32
				5611.92,1685.42 5478.85,1685.42 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5529.8921 1726.2437)'
						className={S.Number}
					>
						61
					</text>
					<text
						transform='matrix(1 0 0 1 5524.5732 1755.6228)'
						className={S.Plan}
					>
						A4
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A')}
				className={unitClass(b, '62')}
				id='UNIT-62-A'
			>
				<polygon
					className={S.Hot}
					points='5476.85,1645.61 5461.64,1645.61 5461.64,1648.89 5467.56,1648.89 5467.56,1685.42 5478.85,1685.42
				5611.92,1685.42 5611.92,1593.89 5476.85,1593.89 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5527.0708 1635.6597)'
						className={S.Number}
					>
						62
					</text>
					<text
						transform='matrix(1 0 0 1 5533.2969 1665.0388)'
						className={S.Plan}
					>
						A
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A')}
				className={unitClass(b, '63')}
				id='UNIT-63-A'
			>
				<polygon
					className={S.Hot}
					points='5478.85,1503.63 5478.85,1554.09 5463.64,1554.09 5463.64,1557.37 5469.56,1557.37 5469.56,1593.89
				5476.85,1593.89 5611.92,1593.89 5611.92,1503.63 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5527.0063 1545.0759)'
						className={S.Number}
					>
						63
					</text>
					<text
						transform='matrix(1 0 0 1 5533.2969 1574.4551)'
						className={S.Plan}
					>
						A
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A')}
				className={unitClass(b, '64')}
				id='UNIT-64-A'
			>
				<polygon
					className={S.Hot}
					points='5611.92,1411.37 5478.85,1411.37 5478.85,1463.82 5465.64,1463.82 5465.64,1467.1 5471.56,1467.1
				5471.56,1503.63 5478.85,1503.63 5611.92,1503.63 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5526.1743 1454.4924)'
						className={S.Number}
					>
						64
					</text>
					<text
						transform='matrix(1 0 0 1 5533.2969 1483.8716)'
						className={S.Plan}
					>
						A
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A2')}
				className={unitClass(b, '65')}
				id='UNIT-65-A2'
			>
				<polygon
					className={S.Hot}
					points='5611.92,1320.64 5574.1,1320.64 5524.42,1320.64 5478.85,1320.64 5478.85,1371.56 5465.64,1371.56
				5465.64,1374.84 5471.56,1374.84 5471.56,1411.37 5478.85,1411.37 5611.92,1411.37 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5526.9409 1363.9092)'
						className={S.Number}
					>
						65
					</text>
					<text
						transform='matrix(1 0 0 1 5525.5098 1393.2883)'
						className={S.Plan}
					>
						A2
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building20A;
