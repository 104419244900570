import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building21 = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 21'});
	return (
		<g id='BUILDING-21'>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '29')}
				id='UNIT-29-F1'
			>
				<polygon
					className={S.Hot}
					points='5013.38,1112.77 5013.38,1017.53 4923.16,1017.53 4923.16,1177.7 4923.16,1191.15 4923.16,1203.68
				4975.79,1203.68 4975.79,1191.15 4992.88,1191.15 4992.88,1112.77 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4946.502 1105.8374)'
						className={S.Number}
					>
						29
					</text>
					<text
						transform='matrix(1 0 0 1 4949.8164 1135.2163)'
						className={S.Plan}
					>
						F1
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G')}
				className={unitClass(b, '30')}
				id='UNIT-30-G'
			>
				<polygon
					className={S.Hot}
					points='5013.38,1017.53 5013.38,1112.77 4992.88,1112.77 4992.88,1191.15 5015.66,1191.15 5015.66,1216.89
				5026.37,1216.89 5026.37,1200.26 5065.79,1200.26 5065.79,1017.53 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5022.1416 1105.8374)'
						className={S.Number}
					>
						30
					</text>
					<text
						transform='matrix(1 0 0 1 5029.3945 1135.2163)'
						className={S.Plan}
					>
						G
					</text>
				</g>
			</g>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '31')}
				id='UNIT-31-F'
			>
				<polygon
					className={S.Hot}
					points='5157.84,1111.85 5157.84,1017.53 5065.79,1017.53 5065.79,1200.26 5065.79,1203.68 5119.11,1203.68
				5119.11,1191.15 5138.93,1191.15 5138.93,1111.85 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5091.7495 1105.8374)'
						className={S.Number}
					>
						31
					</text>
					<text
						transform='matrix(1 0 0 1 5096.4019 1135.2163)'
						className={S.Plan}
					>
						F
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G')}
				className={unitClass(b, '32')}
				id='UNIT-32-G'
			>
				<path
					className={S.Hot}
					d='M5209.79,1017.53h-51.95v94.33h-18.91v79.29h20.96v25.75h8.66v-16.63h41.24
				C5209.79,1195.25,5209.79,1017.53,5209.79,1017.53z'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5167.813 1105.8374)'
						className={S.Number}
					>
						32
					</text>
					<text
						transform='matrix(1 0 0 1 5173.6226 1135.2163)'
						className={S.Plan}
					>
						G
					</text>
				</g>
			</g>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '33')}
				id='UNIT-33-F'
			>
				<path
					className={S.Hot}
					d='M5298.65,1111.4v-93.87h-88.86c0,0,0,177.72,0,182.73v2.28h52.4v-11.39h18.23v-79.75H5298.65z'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5233.9834 1105.8372)'
						className={S.Number}
					>
						33
					</text>
					<text
						transform='matrix(1 0 0 1 5241.522 1135.2161)'
						className={S.Plan}
					>
						F
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G1')}
				className={unitClass(b, '34')}
				id='UNIT-34-G1'
			>
				<polygon
					className={S.Hot}
					points='5298.65,1017.53 5298.65,1111.4 5280.42,1111.4 5280.42,1191.15 5302.98,1191.15 5302.98,1215.98
				5311.87,1215.98 5311.87,1201.63 5354.7,1201.63 5354.7,1191.15 5354.7,1017.53 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5310.6045 1105.8372)'
						className={S.Number}
					>
						34
					</text>
					<text
						transform='matrix(1 0 0 1 5312.6567 1135.2161)'
						className={S.Plan}
					>
						G1
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building21;
