import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import S from '../SVG.module.sass';
import Building1 from './Building1';
import Building2 from './Building2';
import Building3 from './Building3';
import Building4 from './Building4';
import Building5 from './Building5';
import Building6 from './Building6';
import Building7 from './Building7';
import Building8A from './Building8A';
import Building8B from './Building8B';
import Building9A from './Building9A';
import Building9B from './Building9B';
import Building10 from './Building10';
import Building11 from './Building11';
import Building12 from './Building12';
import Building16 from './Building16';
import Building17 from './Building17';
import Building18 from './Building18';
import Building19 from './Building19';
import Building20A from './Building20A';
import Building20B from './Building20B';
import Building21 from './Building21';
import {
	selectStatusBuildings,
	selectUnits,
} from '../../../../store/townhomesSlice';
import {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';
import Building22 from './Building22';
import Club from './Club';

const Units = () => {
	const UNITS = useSelector(selectUnits);
	const AllStatusBuildings = useSelector(selectStatusBuildings);
	const unitClass = (build: I__Buildings | undefined, unit: string) => {
		const selectUnit = _.find(UNITS, {unit, 'building type': 'Townhomes'});

		const currentBuilding = _.find(AllStatusBuildings, {
			title: build?.title,
			'building type': 'Townhomes',
		});

		const classes = [
			S.Unit,
			{
				[`${S.Hold}`]: selectUnit?.status === 'hold',
				[`${S.Sold}`]: selectUnit?.status === 'sold',
				[`${S.Disable}`]:
					selectUnit?.status === 'disable' ||
					currentBuilding?.status === 'disable',
			},
		];
		return classNames(classes);
	};

	const click = (planName: T__Plan_Name) => {
		// @ts-ignore
		const lowercasePlan = planName.toLowerCase();
		const url = `https://highstreetvillage.ca/staging/townhomes/townhome-${lowercasePlan}`;
		window.open(url, '_self');
	};

	return (
		<g id='UNITS' className={S.Units}>
			<Building1 unitClass={unitClass} click={click} />
			<Building2 unitClass={unitClass} click={click} />
			<Building3 unitClass={unitClass} click={click} />
			<Building4 unitClass={unitClass} click={click} />
			<Building5 unitClass={unitClass} click={click} />
			<Building6 unitClass={unitClass} click={click} />
			<Building7 unitClass={unitClass} click={click} />
			<Building8A unitClass={unitClass} click={click} />
			<Building8B unitClass={unitClass} click={click} />
			<Building9A unitClass={unitClass} click={click} />
			<Building9B unitClass={unitClass} click={click} />
			<Building10 unitClass={unitClass} click={click} />
			<Building11 unitClass={unitClass} click={click} />
			<Building12 unitClass={unitClass} click={click} />
			<Building16 unitClass={unitClass} click={click} />
			<Building17 unitClass={unitClass} click={click} />
			<Building18 unitClass={unitClass} click={click} />
			<Building19 unitClass={unitClass} click={click} />
			<Building20A unitClass={unitClass} click={click} />
			<Building20B unitClass={unitClass} click={click} />
			<Building21 unitClass={unitClass} click={click} />
			<Building22 unitClass={unitClass} click={click} />
			<Club />
		</g>
	);
};

export default Units;
