import S from '../SVG.module.sass';

const MyComponent = () => {
	return (
		<g id='OUTLINE' className={S.Outline}>
			<polygon
				id='B-1'
				points='4033.442,613.477 4033.442,675.509 4063.062,675.509 4063.062,725.863 4033.442,725.863
		4033.442,799.458 3889.783,799.458 3889.783,848.217 3746.239,848.217 3746.239,896.749 3603.15,896.749 3603.15,775.306
		3589.138,775.306 3589.138,763.914 3603.15,763.914 3603.15,710.597 3654.986,710.597 3654.986,721.078 3694.859,721.078
		3694.859,696.015 3704.885,696.015 3704.885,712.192 3746.239,712.192 3746.239,662.293 3797.391,662.293 3797.391,672.547
		3838.632,672.547 3838.632,647.483 3847.973,647.483 3847.973,662.977 3889.783,662.977 3889.783,613.477 3940.024,613.477
		3940.024,624.015 3980.809,624.015 3980.809,598.952 3990.606,598.952 3990.606,613.477 	'
			/>
			<polygon
				id='B-2'
				points='4114.011,621.195 4114.011,764.483 4205.492,764.483 4205.492,766.876 4296.076,766.876
		4296.076,764.483 4479.663,764.483 4479.663,630.311 4428.441,630.311 4428.441,621.195 4386.657,621.195 4386.657,630.311
		4336.847,630.311 4336.847,621.195 4296.076,621.195 4296.076,630.311 4245.935,630.311 4245.935,621.195 4205.492,621.195
		4205.492,630.311 4155.479,630.311 4155.479,621.195 	'
			/>
			<polygon
				id='B-3'
				points='4534.391,612.509 4534.391,799.458 4965.708,799.458 4965.708,611.179 4921.732,611.179
		4921.732,597.129 4912.847,597.129 4912.847,622.192 4871.834,622.192 4871.834,611.179 4819.657,611.179 4819.657,613.99
		4779.555,613.99 4779.555,597.129 4769.758,597.129 4769.758,622.648 4729.657,622.648 4729.657,611.179 4676.568,611.179
		4676.568,615.129 4635.783,615.129 4635.783,597.129 4625.986,597.129 4625.986,622.876 4585.884,622.876 4585.884,612.509 	'
			/>
			<polygon
				id='B-4'
				points='5028.821,622.192 5028.821,765.053 5119.277,765.053 5119.277,768.015 5301.669,768.015
		5301.669,765.053 5394.745,765.053 5394.745,725.863 5423.682,725.863 5423.682,674.825 5394.518,674.825 5394.518,631.021
		5342.34,631.021 5342.34,622.192 5301.669,622.192 5301.669,631.021 5251.657,631.021 5251.657,622.192 5211.1,622.192
		5211.1,631.021 5160.062,631.021 5160.062,622.192 5119.277,622.192 5119.277,631.021 5069.606,631.021 5069.606,622.192 	'
			/>
			<polygon
				id='B-5'
				points='5547.347,690.763 5584.106,727.523 5576.359,735.27 5604.294,763.205 5622.75,744.749
		5630.131,752.13 5619.057,763.205 5685.422,828.839 5677.676,836.586 5706.157,865.067 5724.157,846.156 5731.068,853.067
		5720.359,863.776 5786.739,930.156 5778.537,937.447 5806.866,965.776 5825.321,947.32 5832.612,954.611 5821.448,965.776
		5852.359,995.776 5721.575,1126.561 5416.562,821.548 	'
			/>
			<polygon
				id='B-6'
				points='5794.752,1144.523 5928.765,1144.523 5928.765,1195.409 5938.106,1195.409
		5938.106,1236.042 5928.765,1236.042 5928.765,1286.396 5938.106,1286.396 5938.106,1327.029 5951.828,1327.029 5951.828,1377.535
		5961.043,1377.535 5961.043,1418.092 5951.828,1418.092 5951.828,1468.523 5961.043,1468.523 5961.043,1509.232 5951.828,1509.232
		5951.828,1559.662 5961.043,1559.662 5961.043,1600.295 5974.486,1600.295 5974.486,1650.497 5984.131,1650.497 5984.131,1691.358
		5974.486,1691.358 5974.486,1741.029 5984.131,1741.029 5984.131,1782.345 5840.436,1782.345 5840.436,1691.358 5838.005,1691.358
		5838.005,1600.295 5818.41,1600.295 5818.41,1509.232 5815.068,1509.232 5815.068,1418.092 5819.169,1418.092 5819.169,1327.029
		5792.739,1327.029 5792.739,1236.042 5794.752,1236.042 	'
			/>
			<polygon
				id='B-7'
				points='5867.701,1843.333 5917.714,1843.333 5917.714,1872.383 5970.802,1872.383
		5970.802,1914.649 5985.157,1914.649 5985.157,1924.219 5959.752,1924.219 5959.752,1965.232 5971.486,1965.232 5971.486,2017.181
		5968.524,2017.181 5968.524,2057.966 5985.157,2057.966 5985.157,2068.219 5959.752,2068.219 5959.752,2108.092 5971.258,2108.092
		5971.258,2159.586 5968.524,2159.586 5968.524,2201.51 5985.157,2201.51 5985.157,2210.624 5959.752,2210.624 5959.752,2251.409
		5971.258,2251.409 5971.258,2303.358 5967.84,2303.358 5967.84,2345.51 5985.157,2345.51 5985.157,2354.852 5959.752,2354.852
		5959.752,2394.725 5975.815,2394.725 5975.815,2451.687 5917.258,2451.687 5917.258,2461.257 5906.093,2461.257 5906.093,2448.042
		5785.106,2448.042 5785.106,1872.383 5867.701,1872.383 	'
			/>
			<polygon
				id='B-17'
				points='3902.604,1664.039 3902.604,1705.625 3932.637,1705.625 3932.637,1758.18 4011.187,1758.18
		4011.187,1788.795 4060.485,1788.795 4060.485,1758.18 4139.177,1758.18 4139.177,1705.625 4168.287,1705.625 4168.287,1664.039
		4139.177,1664.039 4139.177,1560.017 4168.287,1560.017 4168.287,1518.432 4139.177,1518.432 4139.177,1414.644 4168.287,1414.644
		4168.287,1373.058 4139.177,1373.058 4139.177,1320.269 3932.637,1320.269 3932.637,1373.058 3902.604,1373.058 3902.604,1414.644
		3932.637,1414.644 3932.637,1518.432 3902.604,1518.432 3902.604,1560.017 3932.637,1560.017 3932.637,1664.039 	'
			/>
			<polygon
				id='B-19'
				points='4909.185,1321.269 4909.185,1353.151 4903.64,1353.151 4903.64,1413.218 4909.185,1413.218
		4909.185,1442.79 4903.64,1442.79 4903.64,1504.857 4909.185,1504.857 4909.185,1535.188 4903.64,1535.188 4903.64,1594.255
		4909.185,1594.255 4909.185,1625.586 4903.64,1625.586 4903.64,1685.653 4909.185,1685.653 4909.185,1717.252 4903.64,1717.252
		4903.64,1777.319 4909.185,1777.319 4977.569,1777.319 5070.443,1776.319 5070.443,1320.269 	'
			/>
			<polygon
				id='B-18'
				points='4529.424,1321.498 4529.424,1353.88 4534.968,1353.88 4534.968,1413.947 4529.424,1413.947
		4529.424,1443.519 4534.968,1443.519 4534.968,1505.587 4529.424,1505.587 4529.424,1535.917 4534.968,1535.917 4534.968,1594.985
		4529.424,1594.985 4529.424,1626.315 4534.968,1626.315 4534.968,1686.383 4529.424,1686.383 4529.424,1717.981 4534.968,1717.981
		4534.968,1777.799 4529.424,1777.799 4461.039,1777.799 4368.166,1777.799 4368.166,1321.498 	'
			/>
			<polygon
				id='B-16'
				points='3705.882,1420.731 3705.882,1329.592 3702.236,1329.592 3702.236,1237.085
		3568.717,1237.085 3568.717,1289.035 3560.059,1289.035 3560.059,1329.592 3568.717,1329.592 3568.717,1380.174 3560.059,1380.174
		3560.059,1420.731 3568.717,1420.731 3568.717,1471.313 3560.059,1471.313 3560.059,1511.87 3568.717,1511.87 3568.717,1562.453
		3560.059,1562.453 3560.059,1603.01 3568.717,1603.01 3568.717,1653.592 3560.059,1653.592 3560.059,1694.149 3568.717,1694.149
		3568.717,1745.574 3560.059,1745.574 3560.059,1786.131 3568.717,1786.131 3600.616,1786.131 3702.236,1786.131 3702.236,1694.149
		3705.882,1694.149 3705.882,1603.01 3702.236,1603.01 3702.236,1420.731 	'
			/>
			<polygon
				id='B-22'
				points='4516.676,1108.664 4487.056,1108.664 4487.056,1040.766 4394.094,1040.766
		4394.094,1017.525 3965.739,1017.525 3965.739,1069.019 3915.157,1069.019 3874.144,1069.019 3874.144,1201.171 3874.144,1211.652
		3915.157,1211.652 3915.157,1201.171 3965.739,1201.171 3965.739,1205.272 4017.689,1205.272 4017.689,1201.171 4017.689,1190.234
		4057.334,1190.234 4057.334,1217.803 4066.22,1217.803 4066.22,1201.171 4108.144,1201.171 4108.144,1203.335 4159.866,1203.335
		4159.866,1190.234 4201.106,1190.234 4201.106,1217.803 4210.448,1217.803 4210.448,1201.171 4251.461,1201.171 4251.461,1203.335
		4304.094,1203.335 4304.094,1190.234 4344.878,1190.234 4344.878,1201.171 4344.878,1216.436 4353.537,1216.436 4353.537,1201.171
		4394.094,1201.171 4394.094,1216.436 4403.436,1216.436 4403.436,1201.171 4435.562,1201.171 4435.562,1207.55 4491.613,1207.55
		4491.613,1157.88 4516.676,1157.88 	'
			/>
			<polygon
				id='B-21'
				points='4923.157,1017.525 4923.157,1177.702 4923.157,1191.145 4923.157,1203.677
		4975.79,1203.677 4975.79,1191.145 5015.664,1191.145 5015.664,1216.892 5026.372,1216.892 5026.372,1200.259 5065.79,1200.259
		5065.79,1203.677 5119.106,1203.677 5119.106,1191.145 5159.891,1191.145 5159.891,1216.892 5168.549,1216.892 5168.549,1200.259
		5209.79,1200.259 5209.79,1202.538 5262.195,1202.538 5262.195,1191.145 5302.98,1191.145 5302.98,1215.981 5311.866,1215.981
		5311.866,1201.626 5354.701,1201.626 5354.701,1191.145 5354.701,1017.525 	'
			/>
			<polygon
				id='B-11'
				points='4056.888,2007.225 4056.888,2010.045 4047.403,2010.045 4047.403,2023.631
		3952.557,2023.631 3952.557,2037.217 3900.519,2037.217 3900.519,2129.757 3909.235,2129.757 3909.235,2166.414 3900.519,2166.414
		3900.519,2348.16 3909.235,2348.16 3909.235,2383.279 3900.519,2383.279 3900.519,2475.819 3952.557,2475.819 3952.557,2503.504
		3961.272,2503.504 3961.272,2489.662 4056.888,2489.662 4056.888,2475.819 4109.438,2475.819 4109.438,2383.279 4100.21,2383.279
		4100.21,2348.16 4109.438,2348.16 4109.438,2166.414 4100.21,2166.414 4100.21,2129.757 4109.438,2129.757 4109.438,2037.217
		4109.438,2007.225 	'
			/>
			<polygon
				id='B-12'
				points='3750.836,2130.544 3747.646,2130.544 3747.646,2039.405 3614.583,2039.405
		3614.583,2090.899 3604.558,2090.899 3604.558,2130.544 3614.583,2130.544 3614.583,2180.671 3604.558,2180.671 3604.558,2221.456
		3614.583,2221.456 3614.583,2272.266 3604.558,2272.266 3604.558,2312.367 3614.583,2312.367 3614.583,2363.405 3604.558,2363.405
		3604.558,2404.418 3614.583,2404.418 3712.102,2404.418 3747.646,2404.418 3747.646,2312.367 3750.836,2312.367 	'
			/>
			<polygon
				id='B-CLUB'
				points='4675.007,1076.101 4819.89,1076.101 4819.89,1173.83 4802.149,1173.83 4802.149,1285.255
		4583.969,1285.255 4583.969,1261.289 4574.32,1261.289 4574.32,1186.28 4583.658,1186.28 4583.658,1095.086 4675.007,1095.086 	'
			/>
			<polygon
				id='B-20A'
				points='5574.095,1320.64 5574.095,1292.886 5524.424,1292.886 5524.424,1320.64 5478.854,1320.64
		5478.854,1371.561 5465.639,1371.561 5465.639,1374.839 5471.563,1374.839 5471.563,1411.367 5478.854,1411.367 5478.854,1463.824
		5465.639,1463.824 5465.639,1467.103 5471.563,1467.103 5471.563,1503.631 5478.854,1503.631 5478.854,1554.088 5463.639,1554.088
		5463.639,1557.366 5469.563,1557.366 5469.563,1593.894 5476.854,1593.894 5476.854,1645.615 5461.639,1645.615 5461.639,1648.893
		5467.563,1648.893 5467.563,1685.421 5478.854,1685.421 5478.854,1737.513 5464.639,1737.513 5464.639,1740.792 5470.563,1740.792
		5470.563,1777.319 5478.854,1777.319 5519.323,1777.319 5611.918,1777.319 5611.918,1320.64 	'
			/>
			<polygon
				id='B-20B'
				points='5346.348,1320.64 5346.348,1371.561 5359.563,1371.561 5359.563,1374.839
		5353.639,1374.839 5353.639,1411.367 5346.348,1411.367 5346.348,1463.824 5359.563,1463.824 5359.563,1467.103 5353.639,1467.103
		5353.639,1503.631 5346.348,1503.631 5346.348,1554.088 5361.563,1554.088 5361.563,1557.366 5355.639,1557.366 5355.639,1593.894
		5348.348,1593.894 5348.348,1645.615 5363.563,1645.615 5363.563,1648.893 5357.639,1648.893 5357.639,1685.421 5346.348,1685.421
		5346.348,1737.513 5360.563,1737.513 5360.563,1740.792 5354.639,1740.792 5354.639,1777.319 5346.348,1777.319 5305.88,1777.319
		5213.285,1777.319 5213.285,1320.64 	'
			/>
			<polygon
				id='B-8A'
				points='5517.589,2030.48 5517.589,2081.401 5504.374,2081.401 5504.374,2084.679
		5510.297,2084.679 5510.297,2121.207 5517.589,2121.207 5517.589,2173.664 5504.374,2173.664 5504.374,2176.943 5510.297,2176.943
		5510.297,2213.471 5517.589,2213.471 5517.589,2263.928 5502.374,2263.928 5502.374,2267.207 5508.297,2267.207 5508.297,2303.734
		5515.589,2303.734 5515.589,2355.455 5500.374,2355.455 5500.374,2358.733 5506.297,2358.733 5506.297,2395.261 5517.589,2395.261
		5517.589,2447.353 5503.374,2447.353 5503.374,2450.632 5509.297,2450.632 5509.297,2487.16 5517.589,2487.16 5558.057,2487.16
		5650.652,2487.16 5650.652,2030.48 	'
			/>
			<polygon
				id='B-8B'
				points='5385.082,2030.48 5385.082,2081.401 5398.297,2081.401 5398.297,2084.679
		5392.374,2084.679 5392.374,2121.207 5385.082,2121.207 5385.082,2173.664 5398.297,2173.664 5398.297,2176.943 5392.374,2176.943
		5392.374,2213.471 5385.082,2213.471 5385.082,2263.928 5400.297,2263.928 5400.297,2267.207 5394.374,2267.207 5394.374,2303.734
		5387.082,2303.734 5387.082,2355.455 5402.297,2355.455 5402.297,2358.733 5396.374,2358.733 5396.374,2395.261 5385.082,2395.261
		5385.082,2447.353 5399.297,2447.353 5399.297,2450.632 5393.374,2450.632 5393.374,2487.16 5385.082,2487.16 5344.614,2487.16
		5252.019,2487.16 5252.019,2030.48 	'
			/>
			<polygon
				id='B-10'
				points='4434.095,2036.278 4434.095,2086.202 4422.551,2086.202 4422.551,2128.025
		4434.095,2128.025 4434.095,2228.818 4422.551,2228.818 4422.551,2270.81 4434.095,2270.81 4434.095,2370.456 4422.551,2370.456
		4422.551,2412.987 4434.095,2412.987 4434.095,2462.203 4249.386,2462.203 4249.386,2036.278 	'
			/>
			<polygon
				id='B-9B'
				points='4536.46,2049.285 4823.938,2049.285 4823.938,2233.056 4772.659,2232.927
		4772.881,2222.222 4731.336,2222.222 4731.251,2233.491 4629.148,2233.491 4629.148,2222.222 4586.847,2222.222 4586.833,2233.056
		4537.459,2233.056 	'
			/>
			<polygon
				id='B-9A'
				points='4926.868,2037.843 5112.354,2037.843 5111.355,2463.114 4926.704,2463.114
		4926.704,2412.893 4937.782,2412.893 4937.782,2370.099 4926.868,2370.099 4926.868,2270.851 4937.646,2270.851 4937.646,2228.946
		4927.168,2228.946 4927.168,2129.192 4937.646,2129.192 4937.646,2086.401 4926.868,2086.401 	'
			/>
		</g>
	);
};

export default MyComponent;
