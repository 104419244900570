import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building10 = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 10'});
	return (
		<g id='BUILDING-10'>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '91')}
				id='UNIT-91-F3'
			>
				<polygon
					className={S.Hot}
					points='4345.994,2372.645 4249.386,2372.645 4249.386,2462.203 4434.095,2462.203 4434.095,2412.987
				4422.551,2412.987 4422.551,2392.821 4345.994,2392.821 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4287.4316 2410.2524)'
						className={S.Number}
					>
						91
					</text>
					<text
						transform='matrix(1 0 0 1 4285.5776 2439.6316)'
						className={S.Plan}
					>
						F3
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G')}
				className={unitClass(b, '92')}
				id='UNIT-92-G'
			>
				<polygon
					className={S.Hot}
					points='4249.386,2372.645 4345.994,2372.645 4345.994,2392.821 4422.551,2392.821 4422.551,2370.456
				4434.095,2370.456 4434.095,2318.81 4249.386,2318.81 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4370.7368 2351.4678)'
						className={S.Number}
					>
						92
					</text>
					<text
						transform='matrix(1 0 0 1 4377.0405 2380.8467)'
						className={S.Plan}
					>
						G
					</text>
				</g>
			</g>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '93')}
				id='UNIT-93-F'
			>
				<polygon
					className={S.Hot}
					points='4345.994,2248.994 4345.994,2228.818 4249.386,2228.818 4249.386,2318.81 4434.095,2318.81
				4434.095,2270.81 4422.551,2270.81 4422.551,2248.994 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4284.5459 2268.0757)'
						className={S.Number}
					>
						93
					</text>
					<text
						transform='matrix(1 0 0 1 4292.5776 2297.4546)'
						className={S.Plan}
					>
						F
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G')}
				className={unitClass(b, '94')}
				id='UNIT-94-G'
			>
				<polygon
					className={S.Hot}
					points='4345.994,2248.994 4422.551,2248.994 4422.551,2228.818 4434.095,2228.818 4434.095,2178.456
				4249.386,2178.456 4249.386,2228.818 4345.994,2228.818 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4369.8403 2209.2908)'
						className={S.Number}
					>
						94
					</text>
					<text
						transform='matrix(1 0 0 1 4377.0405 2238.6699)'
						className={S.Plan}
					>
						G
					</text>
				</g>
			</g>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '95')}
				id='UNIT-95-F'
			>
				<polygon
					className={S.Hot}
					points='4434.095,2128.025 4422.551,2128.025 4422.551,2106.377 4345.994,2106.377 4345.994,2086.202
				4249.386,2086.202 4249.386,2178.456 4434.095,2178.456 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4284.4805 2125.8987)'
						className={S.Number}
					>
						95
					</text>
					<text
						transform='matrix(1 0 0 1 4292.5776 2155.2778)'
						className={S.Plan}
					>
						F
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G1')}
				className={unitClass(b, '96')}
				id='UNIT-96-G1'
			>
				<polygon
					className={S.Hot}
					points='4345.994,2106.377 4422.551,2106.377 4422.551,2086.202 4434.095,2086.202 4434.095,2036.278
				4249.386,2036.278 4249.386,2086.202 4345.994,2086.202 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4370.1782 2067.1138)'
						className={S.Number}
					>
						96
					</text>
					<text
						transform='matrix(1 0 0 1 4372.3872 2096.4932)'
						className={S.Plan}
					>
						G1
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building10;
