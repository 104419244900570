import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building3 = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 3'});
	return (
		<g id='BUILDING-3'>
			<g
				onClick={() => click('F1')}
				className={unitClass(b, '19')}
				id='UNIT-19-F1'
			>
				<polygon
					className={S.Hot}
					points='4606.196,707.089 4606.196,624.608 4587.057,624.608 4587.057,613.899 4535.563,614.582
				4535.563,801.19 4625.563,801.19 4625.563,707.089 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4558.0176 701.964)'
						className={S.Number}
					>
						19
					</text>
					<text
						transform='matrix(1 0 0 1 4558.5107 731.3431)'
						className={S.Plan}
					>
						F1
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G')}
				className={unitClass(b, '20')}
				id='UNIT-20-G'
			>
				<polygon
					className={S.Hot}
					points='4636.956,616.861 4636.956,598.861 4627.158,598.861 4627.158,624.608 4606.196,624.608
				4606.196,707.089 4625.563,707.089 4625.563,801.19 4677.741,801.19 4677.741,616.861 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4633.6445 701.9641)'
						className={S.Number}
					>
						20
					</text>
					<text
						transform='matrix(1 0 0 1 4640.832 731.3431)'
						className={S.Plan}
					>
						G
					</text>
				</g>
			</g>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '21')}
				id='UNIT-21-F'
			>
				<polygon
					className={S.Hot}
					points='4749.057,624.38 4730.829,624.38 4730.829,612.911 4677.741,612.911 4677.741,616.861
				4677.741,801.19 4769.335,801.19 4769.335,706.405 4749.057,706.405 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4701.7603 701.964)'
						className={S.Number}
					>
						21
					</text>
					<text
						transform='matrix(1 0 0 1 4706.3481 731.343)'
						className={S.Plan}
					>
						F
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G')}
				className={unitClass(b, '22')}
				id='UNIT-22-G'
			>
				<polygon
					className={S.Hot}
					points='4749.057,624.38 4749.057,706.405 4769.335,706.405 4769.335,801.19 4820.829,801.19
				4820.829,615.721 4780.728,615.721 4780.728,598.861 4770.931,598.861 4770.931,624.38 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4778.915 701.9641)'
						className={S.Number}
					>
						22
					</text>
					<text
						transform='matrix(1 0 0 1 4784.6592 731.3431)'
						className={S.Plan}
					>
						G
					</text>
				</g>
			</g>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '23')}
				id='UNIT-23-F'
			>
				<polygon
					className={S.Hot}
					points='4892.146,705.721 4892.146,623.924 4873.006,623.924 4873.006,612.911 4820.829,612.911
				4820.829,615.721 4820.829,801.19 4911.741,801.19 4911.741,705.721 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4842.7256 701.9639)'
						className={S.Number}
					>
						23
					</text>
					<text
						transform='matrix(1 0 0 1 4850.1987 731.343)'
						className={S.Plan}
					>
						F
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G1')}
				className={unitClass(b, '24')}
				id='UNIT-24-G1'
			>
				<polygon
					className={S.Hot}
					points='4922.905,612.911 4922.905,598.861 4914.019,598.861 4914.019,623.924 4892.146,623.924
				4892.146,705.721 4911.741,705.721 4911.741,801.19 4966.88,801.19 4966.88,612.911 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4923.6587 701.9639)'
						className={S.Number}
					>
						24
					</text>
					<text
						transform='matrix(1 0 0 1 4925.647 731.3429)'
						className={S.Plan}
					>
						G1
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building3;
