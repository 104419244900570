import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building22 = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 22'});
	return (
		<g id='BUILDING-22'>
			<rect
				id='BUILDING-22-PATCH'
				x='4487.06'
				y='1105.84'
				className={S.Patch}
				width='29.62'
				height='52.04'
			/>
			<g
				onClick={() => click('A3')}
				className={unitClass(b, '11')}
				id='UNIT-11-A3'
			>
				<polygon
					className={S.Hot}
					points='3915.16,1069.02 3874.14,1069.02 3874.14,1201.17 3874.14,1211.65 3915.16,1211.65 3915.16,1201.17
				3965.74,1201.17 3965.74,1069.02 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3911.4248 1125.2021)'
						className={S.Number}
					>
						11
					</text>
					<text
						transform='matrix(1 0 0 1 3903.4802 1154.5813)'
						className={S.Plan}
					>
						A3
					</text>
				</g>
			</g>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '12')}
				id='UNIT-12-F'
			>
				<polygon
					className={S.Hot}
					points='4055.06,1113.22 4055.06,1017.53 3965.74,1017.53 3965.74,1069.02 3965.74,1201.17 3965.74,1205.27
				4017.69,1205.27 4017.69,1201.17 4017.69,1190.23 4035.38,1190.23 4035.38,1113.22 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3991.564 1125.2024)'
						className={S.Number}
					>
						12
					</text>
					<text
						transform='matrix(1 0 0 1 3996.1516 1154.5813)'
						className={S.Plan}
					>
						F
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G')}
				className={unitClass(b, '13')}
				id='UNIT-13-G'
			>
				<polygon
					className={S.Hot}
					points='4055.06,1017.53 4055.06,1113.22 4035.38,1113.22 4035.38,1190.23 4057.33,1190.23 4057.33,1217.8
				4066.22,1217.8 4066.22,1201.17 4108.14,1201.17 4107.46,1017.53 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4067.3884 1125.2024)'
						className={S.Number}
					>
						13
					</text>
					<text
						transform='matrix(1 0 0 1 4070.3767 1154.5815)'
						className={S.Plan}
					>
						G
					</text>
				</g>
			</g>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '14')}
				id='UNIT-14-F'
			>
				<polygon
					className={S.Hot}
					points='4198.6,1113.22 4198.6,1017.53 4107.46,1017.53 4108.14,1201.17 4108.14,1203.34 4159.87,1203.34
				4159.87,1190.23 4179.46,1190.23 4179.46,1113.22 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4129.7891 1125.2024)'
						className={S.Number}
					>
						14
					</text>
					<text
						transform='matrix(1 0 0 1 4135.2734 1154.5813)'
						className={S.Plan}
					>
						F
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G')}
				className={unitClass(b, '15')}
				id='UNIT-15-G'
			>
				<polygon
					className={S.Hot}
					points='4198.6,1017.53 4198.6,1113.22 4179.46,1113.22 4179.46,1190.23 4201.11,1190.23 4201.11,1217.8
				4210.45,1217.8 4210.45,1201.17 4251.46,1201.17 4251.46,1017.53 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4211.8687 1125.2024)'
						className={S.Number}
					>
						15
					</text>
					<text
						transform='matrix(1 0 0 1 4214.9219 1154.5813)'
						className={S.Plan}
					>
						G
					</text>
				</g>
			</g>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '16')}
				id='UNIT-16-F'
			>
				<polygon
					className={S.Hot}
					points='4341.69,1112.77 4341.69,1017.53 4251.46,1017.53 4251.46,1201.17 4251.46,1203.34 4304.09,1203.34
				4304.09,1190.23 4322.55,1190.23 4322.55,1112.77 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4274.1709 1125.2024)'
						className={S.Number}
					>
						16
					</text>
					<text
						transform='matrix(1 0 0 1 4279.3174 1154.5813)'
						className={S.Plan}
					>
						F
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G')}
				className={unitClass(b, '17')}
				id='UNIT-17-G'
			>
				<polygon
					className={S.Hot}
					points='4394.09,1017.53 4341.69,1017.53 4341.69,1112.77 4322.55,1112.77 4322.55,1190.23 4344.88,1190.23
				4344.88,1201.17 4344.88,1216.44 4353.54,1216.44 4353.54,1201.17 4394.09,1201.17 4394.09,1040.77 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4354.5776 1125.2024)'
						className={S.Number}
					>
						17
					</text>
					<text
						transform='matrix(1 0 0 1 4357.4482 1154.5815)'
						className={S.Plan}
					>
						G
					</text>
				</g>
			</g>
			<g
				onClick={() => click('B3')}
				className={unitClass(b, '18')}
				id='UNIT-18-B3'
			>
				<polygon
					className={S.Hot}
					points='4487.06,1108.66 4487.06,1040.77 4394.09,1040.77 4394.09,1201.17 4394.09,1202.08 4394.09,1216.44
				4403.44,1216.44 4403.44,1201.17 4435.56,1201.17 4435.56,1207.55 4491.61,1207.55 4491.61,1158.79 4487.06,1158.79 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4427.2373 1125.2024)'
						className={S.Number}
					>
						18
					</text>
					<text
						transform='matrix(1 0 0 1 4423.2192 1154.5815)'
						className={S.Plan}
					>
						B3
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building22;
