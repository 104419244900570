import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import createRootReducer from './rootReducer';
import {useDispatch} from 'react-redux';
import townhomesSlice from './townhomesSlice';

const reducer = createRootReducer({townhomes: townhomesSlice});

const createStore = () => {
	const store = configureStore({
		reducer,
	});
	return store;
};

export const store = createStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export type Store = ReturnType<typeof createStore>;
