import S from './Sitemap.module.sass';
import bgImage from '../../../images/siteplan-V4.jpg';
import SVG from '../SVG';

const Sitemap = () => {
	return (
		<div className={S.Sitemap}>
			<img src={bgImage} alt='highstreet site map' />
			<div className={S.SVGContainer}>
				<SVG />
			</div>
		</div>
	);
};

export default Sitemap;
