import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building16 = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 16'});
	return (
		<g id='BUILDING-16'>
			<g
				onClick={() => click('A4')}
				className={unitClass(b, '126')}
				id='UNIT-126-A4'
			>
				<polygon
					className={S.Hot}
					points='3568.717,1745.574 3560.059,1745.574 3560.059,1786.131 3568.717,1786.131 3600.616,1786.131
				3702.236,1786.131 3702.236,1694.149 3568.717,1694.149 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3616.606 1734.9764)'
						className={S.Number}
					>
						126
					</text>
					<text
						transform='matrix(1 0 0 1 3619.2561 1764.3556)'
						className={S.Plan}
					>
						A4
					</text>
				</g>
			</g>
			<g
				onClick={() => click('Aa')}
				className={unitClass(b, '127')}
				id='UNIT-127-Aa'
			>
				<polygon
					className={S.Hot}
					points='3568.717,1653.592 3560.059,1653.592 3560.059,1694.149 3568.717,1694.149 3702.236,1694.149
				3705.882,1694.149 3705.882,1603.01 3702.236,1603.01 3568.717,1603.01 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3617.2158 1644.0033)'
						className={S.Number}
					>
						127
					</text>
					<text
						transform='matrix(1 0 0 1 3620.4519 1673.3824)'
						className={S.Plan}
					>
						Aa
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A')}
				className={unitClass(b, '128')}
				id='UNIT-128-A'
			>
				<polygon
					className={S.Hot}
					points='3568.717,1511.87 3568.717,1562.453 3560.059,1562.453 3560.059,1603.01 3568.717,1603.01
				3702.236,1603.01 3702.236,1511.87 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3616.9441 1553.0297)'
						className={S.Number}
					>
						128
					</text>
					<text
						transform='matrix(1 0 0 1 3627.98 1582.4086)'
						className={S.Plan}
					>
						A
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A')}
				className={unitClass(b, '129')}
				id='UNIT-129-A'
			>
				<polygon
					className={S.Hot}
					points='3702.236,1420.731 3568.717,1420.731 3568.717,1471.313 3560.059,1471.313 3560.059,1511.87
				3568.717,1511.87 3702.236,1511.87 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3616.6047 1462.0568)'
						className={S.Number}
					>
						129
					</text>
					<text
						transform='matrix(1 0 0 1 3627.9785 1491.4357)'
						className={S.Plan}
					>
						A
					</text>
				</g>
			</g>
			<g
				onClick={() => click('Aa')}
				className={unitClass(b, '130')}
				id='UNIT-130-Aa'
			>
				<polygon
					className={S.Hot}
					points='3705.882,1420.731 3705.882,1329.592 3702.236,1329.592 3568.717,1329.592 3568.717,1380.174
				3560.059,1380.174 3560.059,1420.731 3568.717,1420.731 3702.236,1420.731 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3615.656 1371.0836)'
						className={S.Number}
					>
						130
					</text>
					<text
						transform='matrix(1 0 0 1 3620.4517 1400.4628)'
						className={S.Plan}
					>
						Aa
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A2')}
				className={unitClass(b, '131')}
				id='UNIT-131-A2'
			>
				<polygon
					className={S.Hot}
					points='3702.236,1237.085 3568.717,1237.085 3568.717,1289.035 3560.059,1289.035 3560.059,1329.592
				3568.717,1329.592 3702.236,1329.592 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3619.9202 1280.1107)'
						className={S.Number}
					>
						131
					</text>
					<text
						transform='matrix(1 0 0 1 3620.1924 1309.4899)'
						className={S.Plan}
					>
						A2
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building16;
