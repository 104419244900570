import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import _ from 'lodash';
import {unitsAPI, statusBuildingsAPI} from './api';
import I__Initial_State, {I__StatusBuilding, I__Unit} from './I__InitialState';
import R, {I__Page} from '../constants/routes';

const initialState: I__Initial_State = {
	page: R.SITEMAP,
	units: [],
	statusBuildings: [],
};

export const fetchUnits = createAsyncThunk('townhomes/fetchUnits', async () => {
	const response = await unitsAPI();
	return response;
});

export const fetchStatusBuildings = createAsyncThunk(
	'presentation/fetchStatusBuildings',
	async () => {
		const response = await statusBuildingsAPI();
		return response;
	}
);

export const townhomesSlice = createSlice({
	name: 'townhomes',
	initialState,
	reducers: {
		updatePage: {
			reducer: (state, action: PayloadAction<{page: I__Page}>) => {
				const {page} = action.payload;
				state.page = page;
			},
			prepare: ({page}: {page: I__Page}) => ({
				payload: {page},
			}),
		},
	},
	extraReducers: {
		[fetchUnits.fulfilled.toString()]: (state, action: any) => {
			const data = action.payload;
			const ordered = _.orderBy(
				data,
				(u: I__Unit) => {
					return +u.unit;
				},
				'desc'
			);
			state.units = ordered;
		},
		[fetchStatusBuildings.fulfilled.toString()]: (state, action: any) => {
			const data = action.payload;
			const ordered = _.orderBy(
				data,
				(u: I__StatusBuilding) => {
					// @ts-ignore
					const num = _.replace(u.title, 'Building ', '');
					return +num;
				},
				'asc'
			);
			// console.log(ordered);
			state.statusBuildings = ordered;
		},
	},
});

export const {updatePage} = townhomesSlice.actions;

// @ts-ignore
export const selectPage = (state: RootState) => state.townhomes.page;

// @ts-ignore
export const selectUnits = (state: RootState) => state.townhomes.units;

export const selectStatusBuildings = (state: RootState) =>
	// @ts-ignore
	state.townhomes.statusBuildings;

export default townhomesSlice.reducer;
