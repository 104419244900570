import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building5 = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 5'});
	return (
		<g id='BUILDING-5'>
			<g
				onClick={() => click('F1')}
				className={unitClass(b, '35')}
				id='UNIT-35-F1'
			>
				<polygon
					className={S.Hot}
					points='5533.847,809.995 5591.753,752.089 5577.703,738.038 5585.449,730.291 5548.69,693.532
				5417.905,824.316 5482.6,889.011 5547.992,823.619 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5493.5527 789.2851)'
						className={S.Number}
					>
						35
					</text>
					<text
						transform='matrix(1 0 0 1 5501.1562 818.6641)'
						className={S.Plan}
					>
						F1
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G')}
				className={unitClass(b, '36')}
				id='UNIT-36-G'
			>
				<polygon
					className={S.Hot}
					points='5620.4,765.973 5631.475,754.899 5624.093,747.517 5605.638,765.973 5591.753,752.089
				5533.847,809.995 5547.992,823.619 5482.6,889.011 5518.956,925.367 5649.536,794.787 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5550.7417 834.2346)'
						className={S.Number}
					>
						36
					</text>
					<text
						transform='matrix(1 0 0 1 5557.1099 863.6136)'
						className={S.Plan}
					>
						G
					</text>
				</g>
			</g>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '37')}
				id='UNIT-37-F'
			>
				<polygon
					className={S.Hot}
					points='5635.386,911.013 5693.032,853.367 5679.019,839.354 5686.766,831.608 5649.536,794.787
				5518.956,925.367 5584.006,990.418 5649.399,925.025 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5594.1147 885.0067)'
						className={S.Number}
					>
						37
					</text>
					<text
						transform='matrix(1 0 0 1 5601.5366 914.3858)'
						className={S.Plan}
					>
						F
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G')}
				className={unitClass(b, '38')}
				id='UNIT-38-G'
			>
				<polygon
					className={S.Hot}
					points='5721.703,866.544 5732.412,855.835 5725.5,848.924 5707.5,867.835 5693.032,853.367
				5635.386,911.013 5649.399,925.025 5584.006,990.418 5620.804,1027.215 5751.646,896.487 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5652.5635 932.8633)'
						className={S.Number}
					>
						38
					</text>
					<text
						transform='matrix(1 0 0 1 5658.5938 962.2424)'
						className={S.Plan}
					>
						G
					</text>
				</g>
			</g>
			<g
				onClick={() => click('F')}
				className={unitClass(b, '39')}
				id='UNIT-39-F'
			>
				<polygon
					className={S.Hot}
					points='5736.437,1012.063 5794.083,954.418 5779.88,940.215 5788.083,932.924 5751.646,896.487
				5620.804,1027.215 5685.171,1091.582 5750.563,1026.19 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5696.8872 988.9892)'
						className={S.Number}
					>
						39
					</text>
					<text
						transform='matrix(1 0 0 1 5704.9194 1018.3683)'
						className={S.Plan}
					>
						F
					</text>
				</g>
			</g>
			<g
				onClick={() => click('G1')}
				className={unitClass(b, '40')}
				id='UNIT-40-G1'
			>
				<polygon
					className={S.Hot}
					points='5822.791,968.544 5833.956,957.38 5826.665,950.089 5808.209,968.544 5794.083,954.418
				5736.437,1012.063 5750.563,1026.19 5685.171,1091.582 5722.918,1129.329 5853.703,998.544 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5751.1592 1030.3224)'
						className={S.Number}
					>
						40
					</text>
					<text
						transform='matrix(1 0 0 1 5754.5908 1059.7014)'
						className={S.Plan}
					>
						G1
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building5;
