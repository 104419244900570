import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building20B = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 20B'});
	return (
		<g id='BUILDING-20'>
			<g
				onClick={() => click('A2')}
				className={unitClass(b, '66')}
				id='UNIT-66-A2'
			>
				<polygon
					className={S.Hot}
					points='5353.64,1411.37 5353.64,1374.84 5359.56,1374.84 5359.56,1371.56 5346.35,1371.56 5346.35,1320.64
				5213.28,1320.64 5213.28,1411.37 5346.35,1411.37 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5267.5146 1363.9092)'
						className={S.Number}
					>
						66
					</text>
					<text
						transform='matrix(1 0 0 1 5266.5127 1393.2883)'
						className={S.Plan}
					>
						A2
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A')}
				className={unitClass(b, '67')}
				id='UNIT-67-A'
			>
				<polygon
					className={S.Hot}
					points='5353.64,1503.63 5353.64,1467.1 5359.56,1467.1 5359.56,1463.82 5346.35,1463.82 5346.35,1411.37
				5213.28,1411.37 5213.28,1503.63 5346.35,1503.63 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5268.126 1454.4924)'
						className={S.Number}
					>
						67
					</text>
					<text
						transform='matrix(1 0 0 1 5274.2998 1483.8716)'
						className={S.Plan}
					>
						A
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A')}
				className={unitClass(b, '68')}
				id='UNIT-68-A'
			>
				<polygon
					className={S.Hot}
					points='5355.64,1593.89 5355.64,1557.37 5361.56,1557.37 5361.56,1554.09 5346.35,1554.09 5346.35,1503.63
				5213.28,1503.63 5213.28,1593.89 5348.35,1593.89 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5267.8525 1545.0759)'
						className={S.Number}
					>
						68
					</text>
					<text
						transform='matrix(1 0 0 1 5274.2998 1574.4551)'
						className={S.Plan}
					>
						A
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A')}
				className={unitClass(b, '69')}
				id='UNIT-69-A'
			>
				<polygon
					className={S.Hot}
					points='5357.64,1685.42 5357.64,1648.89 5363.56,1648.89 5363.56,1645.61 5348.35,1645.61 5348.35,1593.89
				5213.28,1593.89 5213.28,1685.42 5346.35,1685.42 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5263.3677 1635.6597)'
						className={S.Number}
					>
						69
					</text>
					<text
						transform='matrix(1 0 0 1 5270.1528 1665.0388)'
						className={S.Plan}
					>
						A
					</text>
				</g>
			</g>
			<g
				onClick={() => click('A4')}
				className={unitClass(b, '70')}
				id='UNIT-70-A4'
			>
				<polygon
					className={S.Hot}
					points='5213.28,1685.42 5213.28,1777.32 5354.64,1777.32 5354.64,1740.79 5360.56,1740.79 5360.56,1737.51
				5346.35,1737.51 5346.35,1685.42 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 5263.0942 1726.2437)'
						className={S.Number}
					>
						70
					</text>
					<text
						transform='matrix(1 0 0 1 5261.4292 1755.6228)'
						className={S.Plan}
					>
						A4
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building20B;
