import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building19 = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 19'});
	return (
		<g id='BUILDING-19'>
			<g
				onClick={() => click('B2')}
				className={unitClass(b, '80')}
				id='UNIT-80-B2'
			>
				<polygon
					className={S.Hot}
					points='4909.613,1717.623 4904.069,1717.623 4904.069,1777.691 4909.613,1777.691 4977.998,1777.691
				5070.872,1776.691 5070.872,1686.025 4909.613,1686.025 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4969.8105 1725.6655)'
						className={S.Number}
					>
						80
					</text>
					<text
						transform='matrix(1 0 0 1 4970.2383 1755.0444)'
						className={S.Plan}
					>
						B2
					</text>
				</g>
			</g>
			<g
				onClick={() => click('B')}
				className={unitClass(b, '79')}
				id='UNIT-79-B'
			>
				<polygon
					className={S.Hot}
					points='4909.613,1625.957 4904.069,1625.957 4904.069,1686.025 4909.613,1686.025 5070.872,1686.025
				5070.872,1594.627 4909.613,1594.627 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4970.9678 1635.2263)'
						className={S.Number}
					>
						79
					</text>
					<text
						transform='matrix(1 0 0 1 4978.0254 1664.6055)'
						className={S.Plan}
					>
						B
					</text>
				</g>
			</g>
			<g
				onClick={() => click('B')}
				className={unitClass(b, '78')}
				id='UNIT-78-B'
			>
				<polygon
					className={S.Hot}
					points='4909.613,1535.559 4904.069,1535.559 4904.069,1594.627 4909.613,1594.627 5070.872,1594.627
				5070.872,1505.229 4909.613,1505.229 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4971.3062 1544.7874)'
						className={S.Number}
					>
						78
					</text>
					<text
						transform='matrix(1 0 0 1 4978.0254 1574.1663)'
						className={S.Plan}
					>
						B
					</text>
				</g>
			</g>
			<g
				onClick={() => click('B')}
				className={unitClass(b, '77')}
				id='UNIT-77-B'
			>
				<polygon
					className={S.Hot}
					points='4909.613,1443.161 4904.069,1443.161 4904.069,1505.229 4909.613,1505.229 5070.872,1505.229
				5070.872,1413.589 4909.613,1413.589 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4971.5776 1454.3484)'
						className={S.Number}
					>
						77
					</text>
					<text
						transform='matrix(1 0 0 1 4978.0249 1483.7273)'
						className={S.Plan}
					>
						B
					</text>
				</g>
			</g>
			<g
				onClick={() => click('B1')}
				className={unitClass(b, '76')}
				id='UNIT-76-B1'
			>
				<polygon
					className={S.Hot}
					points='4909.613,1321.64 4909.613,1353.522 4904.069,1353.522 4904.069,1413.589 4909.613,1413.589
				5070.872,1413.589 5070.872,1320.64 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4970.9678 1363.9092)'
						className={S.Number}
					>
						76
					</text>
					<text
						transform='matrix(1 0 0 1 4973.3711 1393.2885)'
						className={S.Plan}
					>
						B1
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building19;
