import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building11 = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 11'});
	return (
		<g id='BUILDING-11'>
			<polygon
				id='BUILDING-11-PATCH'
				fill='#808080'
				strokeWidth={0.5}
				stroke='#000000'
				points='4056.137,2007.225 4056.137,2010.045 4047.403,2010.045 4047.403,2039.405
			4109.438,2039.405 4109.438,2034.379 4109.438,2010.045 4109.438,2007.225 		'
			/>
			<g
				onClick={() => click('C2')}
				className={unitClass(b, '114')}
				id='UNIT-114-C2'
			>
				<polygon
					className={S.Hot}
					points='4047.403,2023.631 4002.836,2023.631 4002.836,2148.772 4100.21,2148.772 4100.21,2129.757
				4109.438,2129.757 4109.438,2039.405 4047.403,2039.405 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4034.9121 2090.1797)'
						className={S.Number}
					>
						114
					</text>
					<text
						transform='matrix(1 0 0 1 4036.6921 2119.5586)'
						className={S.Plan}
					>
						C2
					</text>
				</g>
			</g>
			<g
				onClick={() => click('C')}
				className={unitClass(b, '115')}
				id='UNIT-115-C'
			>
				<polygon
					className={S.Hot}
					points='4002.836,2256.646 4109.438,2256.646 4109.438,2166.414 4100.21,2166.414 4100.21,2148.772
				4002.836,2148.772 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4035.6787 2199.4395)'
						className={S.Number}
					>
						115
					</text>
					<text
						transform='matrix(1 0 0 1 4044.4783 2228.8184)'
						className={S.Plan}
					>
						C
					</text>
				</g>
			</g>
			<g
				onClick={() => click('C')}
				className={unitClass(b, '116')}
				id='UNIT-116-C'
			>
				<polygon
					className={S.Hot}
					points='4002.836,2367.051 4100.21,2367.051 4100.21,2348.16 4109.438,2348.16 4109.438,2256.646
				4002.836,2256.646 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4035.2488 2308.6997)'
						className={S.Number}
					>
						116
					</text>
					<text
						transform='matrix(1 0 0 1 4044.478 2338.0786)'
						className={S.Plan}
					>
						C
					</text>
				</g>
			</g>
			<g
				onClick={() => click('C2')}
				className={unitClass(b, '117')}
				id='UNIT-117-C2'
			>
				<polygon
					className={S.Hot}
					points='4002.836,2489.662 4056.888,2489.662 4056.888,2475.819 4109.438,2475.819 4109.438,2383.279
				4100.21,2383.279 4100.21,2367.051 4002.836,2367.051 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4035.8616 2417.9595)'
						className={S.Number}
					>
						117
					</text>
					<text
						transform='matrix(1 0 0 1 4036.6924 2447.3384)'
						className={S.Plan}
					>
						C2
					</text>
				</g>
			</g>
			<g
				onClick={() => click('C2')}
				className={unitClass(b, '118')}
				id='UNIT-118-C2'
			>
				<polygon
					className={S.Hot}
					points='3909.235,2367.051 3909.235,2383.279 3900.519,2383.279 3900.519,2475.819 3952.557,2475.819
				3952.557,2503.504 3961.272,2503.504 3961.272,2489.662 4002.836,2489.662 4002.836,2367.051 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3938.9958 2417.9595)'
						className={S.Number}
					>
						118
					</text>
					<text
						transform='matrix(1 0 0 1 3940.0991 2447.3384)'
						className={S.Plan}
					>
						C2
					</text>
				</g>
			</g>
			<g
				onClick={() => click('C')}
				className={unitClass(b, '119')}
				id='UNIT-119-C'
			>
				<polygon
					className={S.Hot}
					points='4002.836,2256.646 3900.519,2256.646 3900.519,2348.16 3909.235,2348.16 3909.235,2367.051
				4002.836,2367.051 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3938.6555 2308.6997)'
						className={S.Number}
					>
						119
					</text>
					<text
						transform='matrix(1 0 0 1 3947.8848 2338.0786)'
						className={S.Plan}
					>
						C
					</text>
				</g>
			</g>
			<g
				onClick={() => click('C')}
				className={unitClass(b, '120')}
				id='UNIT-120-C'
			>
				<polygon
					className={S.Hot}
					points='4002.836,2148.772 3909.235,2148.772 3909.235,2166.414 3900.519,2166.414 3900.519,2256.646
				4002.836,2256.646 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3934.9507 2199.4395)'
						className={S.Number}
					>
						120
					</text>
					<text
						transform='matrix(1 0 0 1 3947.885 2228.8184)'
						className={S.Plan}
					>
						C
					</text>
				</g>
			</g>
			<g
				onClick={() => click('C2')}
				className={unitClass(b, '121')}
				id='UNIT-121-C2'
			>
				<polygon
					className={S.Hot}
					points='4002.836,2023.631 3952.557,2023.631 3952.557,2037.217 3900.519,2037.217 3900.519,2129.757
				3909.235,2129.757 3909.235,2148.772 4002.836,2148.772 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3939.2163 2090.1797)'
						className={S.Number}
					>
						121
					</text>
					<text
						transform='matrix(1 0 0 1 3940.0989 2119.5586)'
						className={S.Plan}
					>
						C2
					</text>
				</g>
			</g>
		</g>
	);
};

export default Building11;
