import {combineReducers} from '@reduxjs/toolkit';

// @ts-ignore
const createRootReducer = ({townhomes}) => {
	return combineReducers({
		townhomes,
	});
};

export default createRootReducer;
