import S from './Application.module.sass';
import MuiTheme from '../__MuiTheme';

type Props = {
	children: any;
};

const Application = ({children}: Props) => {
	return (
		<MuiTheme>
			<div className={S.Application}>
				<main className={S.Main}>{children}</main>
			</div>
		</MuiTheme>
	);
};

export default Application;
