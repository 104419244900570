import _ from 'lodash';
import S from '../SVG.module.sass';

import BUILDINGS, {I__Buildings} from '../../../../constants/buildings';
import {T__Plan_Name} from '../../../../constants/floorplans/interface';

type Props = {
	unitClass: (build: I__Buildings | undefined, unit: string) => string;
	click: (planName: T__Plan_Name) => void;
};

const Building17 = ({unitClass, click}: Props) => {
	const b = _.find(BUILDINGS, {title: 'Building 17'});
	return (
		<g id='BUILDING-17'>
			<g
				onClick={() => click('D1')}
				className={unitClass(b, '102')}
				id='UNIT-102-D1'
			>
				<polygon
					className={S.Hot}
					points='4139.177,1372.788 4139.177,1319.998 4033.986,1319.998 4033.986,1393.58 4168.287,1393.58
				4168.287,1372.788 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4066.0876 1353.9558)'
						className={S.Number}
					>
						102
					</text>
					<text
						transform='matrix(1 0 0 1 4073.7964 1383.3347)'
						className={S.Plan}
					>
						D1
					</text>
				</g>
			</g>
			<g
				onClick={() => click('D')}
				className={unitClass(b, '103')}
				id='UNIT-103-D'
			>
				<polygon
					className={S.Hot}
					points='4033.986,1466.932 4139.177,1466.932 4139.177,1414.373 4168.287,1414.373 4168.287,1393.58
				4033.986,1393.58 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4066.0222 1426.4302)'
						className={S.Number}
					>
						103
					</text>
					<text
						transform='matrix(1 0 0 1 4078.4487 1455.8093)'
						className={S.Plan}
					>
						D
					</text>
				</g>
			</g>
			<g
				onClick={() => click('D')}
				className={unitClass(b, '104')}
				id='UNIT-104-D'
			>
				<polygon
					className={S.Hot}
					points='4033.986,1539.475 4168.287,1539.475 4168.287,1518.161 4139.177,1518.161 4139.177,1466.932
				4033.986,1466.932 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4065.1899 1498.6577)'
						className={S.Number}
					>
						104
					</text>
					<text
						transform='matrix(1 0 0 1 4078.4485 1528.0369)'
						className={S.Plan}
					>
						D
					</text>
				</g>
			</g>
			<g
				onClick={() => click('D')}
				className={unitClass(b, '105')}
				id='UNIT-105-D'
			>
				<polygon
					className={S.Hot}
					points='4033.986,1611.556 4139.177,1611.556 4139.177,1559.746 4168.287,1559.746 4168.287,1539.475
				4033.986,1539.475 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4065.958 1571.009)'
						className={S.Number}
					>
						105
					</text>
					<text
						transform='matrix(1 0 0 1 4078.45 1600.3882)'
						className={S.Plan}
					>
						D
					</text>
				</g>
			</g>
			<g
				onClick={() => click('D')}
				className={unitClass(b, '106')}
				id='UNIT-106-D'
			>
				<polygon
					className={S.Hot}
					points='4033.986,1683.637 4168.287,1683.637 4168.287,1663.769 4139.177,1663.769 4139.177,1611.556
				4033.986,1611.556 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4065.5286 1643.3608)'
						className={S.Number}
					>
						106
					</text>
					<text
						transform='matrix(1 0 0 1 4078.4492 1672.7397)'
						className={S.Plan}
					>
						D
					</text>
				</g>
			</g>
			<g
				onClick={() => click('D1')}
				className={unitClass(b, '107')}
				id='UNIT-107-D1'
			>
				<polygon
					className={S.Hot}
					points='4033.986,1757.909 4139.177,1757.909 4139.177,1705.354 4168.287,1705.354 4168.287,1683.637
				4033.986,1683.637 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 4066.1404 1715.7117)'
						className={S.Number}
					>
						107
					</text>
					<text
						transform='matrix(1 0 0 1 4073.7964 1745.0906)'
						className={S.Plan}
					>
						D1
					</text>
				</g>
			</g>
			<g
				onClick={() => click('D1')}
				className={unitClass(b, '108')}
				id='UNIT-108-D1'
			>
				<polygon
					className={S.Hot}
					points='4033.986,1757.909 4033.986,1683.637 3902.604,1683.637 3902.604,1705.354 3932.637,1705.354
				3932.637,1757.909 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3960.3074 1715.7117)'
						className={S.Number}
					>
						108
					</text>
					<text
						transform='matrix(1 0 0 1 3968.2368 1745.0906)'
						className={S.Plan}
					>
						D1
					</text>
				</g>
			</g>
			<g
				onClick={() => click('D')}
				className={unitClass(b, '109')}
				id='UNIT-109-D'
			>
				<polygon
					className={S.Hot}
					points='4033.986,1611.556 3932.637,1611.556 3932.637,1663.769 3902.604,1663.769 3902.604,1683.637
				4033.986,1683.637 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3959.969 1643.3608)'
						className={S.Number}
					>
						109
					</text>
					<text
						transform='matrix(1 0 0 1 3972.8896 1672.7397)'
						className={S.Plan}
					>
						D
					</text>
				</g>
			</g>
			<g
				onClick={() => click('D')}
				className={unitClass(b, '110')}
				id='UNIT-110-D'
			>
				<polygon
					className={S.Hot}
					points='4033.986,1539.475 3902.604,1539.475 3902.604,1559.746 3932.637,1559.746 3932.637,1611.556
				4033.986,1611.556 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3963.3496 1571.009)'
						className={S.Number}
					>
						110
					</text>
					<text
						transform='matrix(1 0 0 1 3972.8904 1600.3882)'
						className={S.Plan}
					>
						D
					</text>
				</g>
			</g>
			<g
				onClick={() => click('D')}
				className={unitClass(b, '111')}
				id='UNIT-111-D'
			>
				<polygon
					className={S.Hot}
					points='4033.986,1466.932 3932.637,1466.932 3932.637,1518.161 3902.604,1518.161 3902.604,1539.475
				4033.986,1539.475 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3967.6128 1498.6577)'
						className={S.Number}
					>
						111
					</text>
					<text
						transform='matrix(1 0 0 1 3972.8889 1528.0369)'
						className={S.Plan}
					>
						D
					</text>
				</g>
			</g>
			<g
				onClick={() => click('D')}
				className={unitClass(b, '112')}
				id='UNIT-112-D'
			>
				<polygon
					className={S.Hot}
					points='4033.986,1393.58 3902.604,1393.58 3902.604,1414.373 3932.637,1414.373 3932.637,1466.932
				4033.986,1466.932 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3964.7917 1426.4302)'
						className={S.Number}
					>
						112
					</text>
					<text
						transform='matrix(1 0 0 1 3972.8892 1455.8093)'
						className={S.Plan}
					>
						D
					</text>
				</g>
			</g>
			<g
				onClick={() => click('D1')}
				className={unitClass(b, '113')}
				id='UNIT-113-D1'
			>
				<polygon
					className={S.Hot}
					points='4033.986,1319.998 3932.637,1319.998 3932.637,1372.788 3902.604,1372.788 3902.604,1393.58
				4033.986,1393.58 			'
				/>
				<g className={S.UnitLabel}>
					<text
						transform='matrix(1 0 0 1 3964.7273 1353.9558)'
						className={S.Number}
					>
						113
					</text>
					<text
						transform='matrix(1 0 0 1 3968.2368 1383.3347)'
						className={S.Plan}
					>
						D1
					</text>
				</g>
			</g>
			<rect
				id='Building-17-Patch'
				x='4011.248'
				y='1757.833'
				fill='#808080'
				strokeWidth={0.5}
				stroke='#000000'
				width='49.077'
				height='31.122'
			/>
		</g>
	);
};

export default Building17;
