import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import S from '../SVG.module.sass';
import Buildings, {
	I__Buildings,
	T__Buildings_Title,
} from '../../../../constants/buildings';

const _ = require('lodash');

const BuildingAreas = () => {
	const AreaClass = (building: T__Buildings_Title) => {
		const title = building;
		const b: I__Buildings = _.find(Buildings, {title});

		const classes = [
			S.Area,
			{
				[`${S.Future}`]: b.future,
				[`${S.Disable}`]: true,
			},
		];
		return classNames(classes);
	};
	return (
		<g id='AEARS' className={S.Areas}>
			<g className={AreaClass('Building 1')}>
				<path
					id='AREA_BUILDING_1'
					className={S.Hot}
					d='M3546.39,956.18
		c0,20.48,11.37,39.23,29.47,48.8c21.44,11.33,51.29,23.92,78.16,23.92c44.18,0,96.3-34.03,105.9-40.57
		c1.14-0.78,2.29-1.5,3.49-2.19c17.15-9.89,144.04-83.08,188.55-108.76c23.01-13.28,52.46-20.16,76.82-23.73
		c26.81-3.93,46.88-26.59,47.32-53.68l2.93-180.07c0.5-30.8-24.33-56.04-55.13-56.04h-422.36c-30.45,0-55.14,24.69-55.14,55.14
		V956.18z'
				/>
			</g>
			<g className={AreaClass('Building 2')}>
				<path
					id='AREA_BUILDING_2'
					className={S.Hot}
					d='M4444.56,849.81h-301.29
		c-30.45,0-55.14-24.69-55.14-55.14V619c0-30.45,24.69-55.14,55.14-55.14h301.29c30.45,0,55.14,24.69,55.14,55.14v175.67
		C4499.7,825.13,4475.02,849.81,4444.56,849.81z'
				/>
			</g>
			<g className={AreaClass('Building 3')}>
				<path
					id='AREA_BUILDING_3'
					className={S.Hot}
					d='M4928.51,849.81h-357.27
		c-30.45,0-55.14-24.69-55.14-55.14V619c0-30.45,24.69-55.14,55.14-55.14h357.27c30.45,0,55.14,24.69,55.14,55.14v175.67
		C4983.65,825.13,4958.97,849.81,4928.51,849.81z'
				/>
			</g>
			<g className={AreaClass('Building 4')}>
				<path
					id='AREA_BUILDING_4'
					className={S.Hot}
					d='M5302.6,849.81h-244.36
		c-30.45,0-55.14-24.69-55.14-55.14V613.75c0-30.45,24.69-55.14,55.14-55.14h307.19c40.76,0,93.06,25.33,93.06,83.63v62.65
		c0,15.73-6.71,30.7-18.46,41.17l-100.75,89.78C5329.18,844.84,5316.13,849.81,5302.6,849.81z'
				/>
			</g>
			<g className={AreaClass('Building 5')}>
				<path
					id='AREA_BUILDING_5'
					className={S.Hot}
					d='M5392,817.96l208.53-196.75
		c10.06-9.49,25-11.67,37.33-5.4c37.73,19.19,119.93,66.34,193.38,144.82c73.11,78.11,112.62,150.57,130.06,187.93
		c6.93,14.83,1.83,32.5-11.92,41.39l-177.24,114.61c-2.01,1.3-3.88,2.82-5.56,4.53l-39.58,40.19
		c-13.17,13.38-34.87,12.99-47.55-0.86c-43.26-47.24-137.03-148.75-196.13-206.41c-46.94-45.79-65.49-61.63-83.7-70.96
		C5379.44,860.69,5375.5,833.53,5392,817.96z'
				/>
			</g>
			<g className={AreaClass('Building 6')}>
				<path
					id='AREA_BUILDING_6'
					className={S.Hot}
					d='M6047.79,1793.34h-262.49
		c-23.33,0-42.29-18.81-42.47-42.14c-0.59-78.17-1.77-248.59-1.77-368.59c0-105.04-2.42-129.79-21.3-175.26
		c-6.92-16.65-2.51-35.85,10.92-47.88c11.19-10.03,24.06-21.99,33.23-30.6c7.87-7.39,18.26-11.5,29.06-11.5h203.21
		c19,0,35.68,12.62,40.86,30.9c17.43,61.49,53.21,206.88,53.21,364.03v238.56C6090.26,1774.32,6071.25,1793.34,6047.79,1793.34z'
				/>
			</g>
			<g className={AreaClass('Building 7')}>
				<path
					id='AREA_BUILDING_7'
					className={S.Hot}
					d='M6047.37,2506.24h-276.35
		c-16.54,0-29.95-13.41-29.95-29.95v-413c0-45.45-34.61-100.44-32.86-151.48c1.41-40.93,19.11-59.9,28.1-83
		c4.44-11.41,15.56-18.83,27.81-18.83h283.25c16.54,0,29.95,13.41,29.95,29.95v636.35
		C6077.32,2492.83,6063.91,2506.24,6047.37,2506.24z'
				/>
			</g>
			<g className={AreaClass('Building 8A')}>
				<path
					id='AREA_BUILDING_8A'
					className={S.Hot}
					d='M5655.22,2574.39h-162.24c-14.47,0-26.19-11.73-26.19-26.19v-493.65
		c0-31.52,25.55-57.07,57.07-57.07h110.28c26.11,0,47.28,21.17,47.28,47.28v503.44C5681.41,2562.67,5669.68,2574.39,5655.22,2574.39
		z'
				/>
			</g>
			<g className={AreaClass('Building 8B')}>
				<path
					id='AREA_BUILDING_8B'
					className={S.Hot}
					d='M5413.02,2574.39h-162.24c-14.47,0-26.19-11.73-26.19-26.19v-493.65
		c0-31.52,25.55-57.07,57.06-57.07h110.28c26.11,0,47.28,21.17,47.28,47.28v503.44C5439.21,2562.67,5427.48,2574.39,5413.02,2574.39
		z'
				/>
			</g>
			<g className={AreaClass('Building 9A')}>
				<path
					id='AREA_BUILDING_9A'
					className={S.Hot}
					d='M4929.626,2572.937h164.734c24.35,0,44.088-19.739,44.088-44.089v-484.633
		c0-24.349-19.739-44.089-44.088-44.089h-164.734c-24.349,0-44.088,19.739-44.088,44.089v484.633
		C4885.538,2553.198,4905.277,2572.937,4929.626,2572.937z'
				/>
			</g>
			<g className={AreaClass('Building 9B')}>
				<path
					id='AREA_BUILDING_9B'
					className={S.Hot}
					d='M4815.133,2292.684h-269.316c-16.988,0-30.759-13.771-30.759-30.76v-232.405
		c0-16.988,13.771-30.76,30.759-30.76h269.316c16.988,0,30.76,13.771,30.76,30.76v232.405
		C4845.893,2278.912,4832.121,2292.684,4815.133,2292.684z'
				/>
			</g>
			<g className={AreaClass('Building 10')}>
				<path
					id='AREA_BUILDING_10'
					className={S.Hot}
					d='M4437.816,2569.519h-191.393c-13.213,0-23.924-10.711-23.924-23.924v-503.089
		c0-24.538,19.892-44.43,44.43-44.43h170.886c13.213,0,23.924,10.711,23.924,23.924v523.595
		C4461.741,2558.808,4451.029,2569.519,4437.816,2569.519z'
				/>
			</g>
			<g className={AreaClass('Building 11')}>
				<path
					id='AREA_BUILDING_11'
					className={S.Hot}
					d='M4117.83,2574.39h-227.85
		c-11.58,0-20.96-9.39-20.96-20.96v-506.28c0-27.43,22.24-49.67,49.67-49.67h169.06c28.19,0,51.04,22.85,51.04,51.04v504.91
		C4138.79,2565.01,4129.41,2574.39,4117.83,2574.39z'
				/>
			</g>
			<g className={AreaClass('Building 12')}>
				<path
					id='AREA_BUILDING_12'
					className={S.Hot}
					d='M3770.08,2574.39h-183.9
		c-40.44,0-73.22-32.78-73.22-73.22v-360.22c0-75.13,60.9-136.03,136.03-136.03h94.07c26.5,0,47.97,21.48,47.97,47.97v500.54
		C3791.04,2565.01,3781.65,2574.39,3770.08,2574.39z'
				/>
			</g>
			<g className={AreaClass('Building 16')}>
				<path
					id='AREA_BUILDING_16'
					className={S.Hot}
					d='M3687.89,1892.15h-87.16
		c-76.41,0-138.36-61.94-138.36-138.36v-477.5c0-44.04,35.7-79.74,79.74-79.74h145.78c24.5,0,44.37,19.86,44.37,44.37v606.85
		C3732.26,1872.28,3712.39,1892.15,3687.89,1892.15z'
				/>
			</g>
			<g className={AreaClass('Building 17')}>
				<path
					id='AREA_BUILDING_17'
					className={S.Hot}
					d='M4205.88,1892.41H3859
		c-25.33,0-45.86-20.53-45.86-45.86v-503.93c0-25.33,20.53-45.86,45.86-45.86h346.88c25.33,0,45.86,20.53,45.86,45.86v503.93
		C4251.73,1871.88,4231.2,1892.41,4205.88,1892.41z'
				/>
			</g>
			<g className={AreaClass('Building 18')}>
				<path
					id='AREA_BUILDING_18'
					className={S.Hot}
					d='M4557.19,1892.15h-183.17
		c-20.1,0-36.39-16.29-36.39-36.39v-522.61c0-20.1,16.29-36.39,36.39-36.39h183.17c20.1,0,36.39,16.29,36.39,36.39v522.61
		C4593.58,1875.86,4577.29,1892.15,4557.19,1892.15z'
				/>
			</g>
			<g className={AreaClass('Building 19')}>
				<path
					id='AREA_BUILDING_19'
					className={S.Hot}
					d='M5059.74,1892.15h-161.22
		c-24.96,0-45.19-20.23-45.19-45.19v-509.07c0-24.96,20.23-45.19,45.19-45.19h161.22c24.96,0,45.19,20.23,45.19,45.19v509.07
		C5104.93,1871.92,5084.7,1892.15,5059.74,1892.15z'
				/>
			</g>
			<g className={AreaClass('Building 20A')}>
				<path
					id='AREA_BUILDING_20A'
					className={S.Hot}
					d='M5599.56,1892.15H5474c-24.96,0-45.19-20.23-45.19-45.19v-509.07
		c0-24.96,20.23-45.19,45.19-45.19h125.56c24.96,0,45.19,20.23,45.19,45.19v509.07C5644.75,1871.92,5624.52,1892.15,5599.56,1892.15
		z'
				/>
			</g>
			<g className={AreaClass('Building 20B')}>
				<path
					id='AREA_BUILDING_20B'
					className={S.Hot}
					d='M5355.74,1892.15h-125.56c-24.96,0-45.19-20.23-45.19-45.19v-509.07
		c0-24.96,20.23-45.19,45.19-45.19h125.56c24.96,0,45.19,20.23,45.19,45.19v509.07C5400.93,1871.92,5380.7,1892.15,5355.74,1892.15z
		'
				/>
			</g>
			<g className={AreaClass('Building 21')}>
				<path
					id='AREA_BUILDING_21'
					className={S.Hot}
					d='M4890.66,980.84V1251
		c0,13.85,11.23,25.08,25.08,25.08h463.66c13.85,0,25.08-11.23,25.08-25.08v-189.62c0-58.33-47.29-105.62-105.62-105.62h-383.12
		C4901.89,955.76,4890.66,966.99,4890.66,980.84z'
				/>
			</g>
			<g className={AreaClass('Building 22')}>
				<path
					id='AREA_BUILDING_22'
					className={S.Hot}
					d='M4503.66,1271.1h-648.53
		c-21.98,0-39.79-17.82-39.79-39.8v-132.89c0-14.37,7.75-27.63,20.27-34.68l174.31-98.11c5.96-3.35,12.68-5.12,19.52-5.12h474.22
		c21.98,0,39.79,17.82,39.79,39.8v231C4543.45,1253.29,4525.63,1271.1,4503.66,1271.1z'
				/>
			</g>
		</g>
	);
};

export default BuildingAreas;
