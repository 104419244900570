import axios from 'axios';

// const webiteURL = 'https://presentation-highstreet.wcimediastudios.com/';
//
// const _ = require('lodash');
//
// export const tokenAPI = async () => {
// 	const response = await axios({
// 		method: 'post',
// 		url: `${webiteURL}wp-json/jwt-auth/v1/token`,
// 		data: {
// 			username: 'wciwebadmin',
// 			password: '6fJDdi!SDzRlKRjQ',
// 		},
// 		headers: {
// 			'Content-Type': 'application/json',
// 		},
// 	});
// 	return response.data;
// };

const webiteURL = 'https://highstreetvillage.ca/';

const _ = require('lodash');

export const tokenAPI = async () => {
	const response = await axios({
		method: 'post',
		url: `${webiteURL}wp-json/jwt-auth/v1/token`,
		data: {
			username: 'FifthAvenue',
			password: 'G^&s&WPWvAz@^62iI*pZGlTNfi',
		},
		headers: {
			'Content-Type': 'application/json',
		},
	});
	return response.data;
};
export const unitsAPI = async () => {
	const rr = _.random(1, 100000);
	const url = `${webiteURL}wp-json/highstreet/v2/unit/?rr=${rr}`;
	const response = await axios({
		method: 'GET',
		url,
	});
	return response.data;
};

export const statusAPI = async (token: string, id: number, status: string) => {
	// console.log(id, status);
	const data = {
		meta_box: {
			unit_status: status,
		},
	};
	const response = await axios.post(
		`${webiteURL}wp-json/wp/v2/unit/${id}`,
		data,
		{
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
		}
	);
	// console.log('response.data =', response.data);
	return response.data;
};

export const statusBuildingsAPI = async () => {
	const rr = _.random(1, 100000);
	const url = `${webiteURL}wp-json/highstreet/v2/building/?rr=${rr}`;
	const response = await axios({
		method: 'GET',
		url,
	});
	return response.data;
};

export const updateStatusBuildingsAPI = async (
	token: string,
	id: number,
	status: 'available' | 'disable'
) => {
	// console.log(id, status);
	const data = {
		meta_box: {
			building_status: status,
		},
	};
	// console.log('data =', data);
	const response = await axios.post(
		`${webiteURL}wp-json/wp/v2/building/${id}`,
		data,
		{
			headers: {
				'Content-Type': 'application/json',
				Authorization: token,
			},
		}
	);
	// console.log('response.data =', response.data);
	return response.data;
};
