import {render} from '@wordpress/element';
// @ts-ignore
import {MemoryRouter as Router} from 'react-router-dom';
// import {createHashHistory} from 'history';
import Root from './container/__Root';
import {store} from './store';
import * as serviceWorker from './serviceWorker';
import './sass/app.global.sass';

const applicationContainer = document.getElementById(
	'highstreet-village-townhomes-container'
);

// const history = createHashHistory({window});

applicationContainer &&
	render(
		<Router initialEntries={['/townhomes-application']}>
			<Root store={store} />
		</Router>,
		applicationContainer
	);

serviceWorker.unregister();
